<div class="ui container-home">
  <div class="ui grid">
    <div class="four  wide column  ">
      <div class="ui card filter" id='card-filter' style='background-color:rgb(249, 249, 249);'>
        <div class="content">
          <div class=" ui left floated header">Filtros
            <i class="filter icon light-blue"></i>
          </div>
          <!--<div class="ui right floated vertical  button" style='padding:1em 1em 1em 1em;
                    ' (click)='list()' tabindex="0">
            <div class="visible content">
              <i class="bars icon view"></i>
            </div>
          </div>
          <div class="ui right floated  vertical  button" style='padding:1em 1em 1em 1em;
                    ' (click)='charts()' tabindex="0">
            <div class="visible content">
              <i class="chart bar icon view"></i>
            </div>
          </div>-->
        </div>
        <div class="content">
          <form [formGroup]="formDatos" id='form' class="ui form">
            <div class="field " [ngClass]="(mostrarCharts)?'disabled' : ''">
              <label class='label light-blue'>N° OT </label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="numero">

                  <option value="">Seleccione:N° OT</option>
                  <option *ngFor="let numero of numeroOtFilters" [value]="numero">{{numero}}
                  </option>
                </select>
              </div>

            </div>
            <div class="field">
              <label class='label light-blue'>UT</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="planta">
                  <option value="">Seleccione:Planta</option>
                  <option *ngFor="let planta of plantas" [value]="planta">{{planta}}
                  </option>
                </select>
              </div>
            </div>


            <div class="field">
              <label class='label light-blue'>Descripción</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="descripcion">
                  <option value="">Seleccione:Descripción</option>
                  <option *ngFor="let descripcion of descripciones" [value]="descripcion">{{descripcion}}
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class='label light-blue'>Tipo</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="tipo">
                  <option value="">Seleccione:Tipo</option>
                  <option *ngFor="let tipo of tipos" [value]="tipo">{{tipo}}
                  </option>
                </select>
              </div>
            </div>
            <div class="field" [ngClass]="(mostrarCharts)?'disabled' : ''">
              <label class='label light-blue'>Estado</label>
              <div class="ui input">
                <select class="ui selection dropdown secciones" formControlName="estado">
                  <option value="">Seleccione:Estado</option>
                  <option *ngFor='let item of estados' [value]="item">{{item}}</option>
                </select>
              </div>
            </div>
            <div class="field" *ngIf="mostrarCharts">
              <label class='label light-blue'>Año</label>

              <div class="ui input">
                <select class="ui selection dropdown secciones" formControlName="anio">
                  <option value="">Seleccione:Año</option>
                  <option *ngFor='let item of anios' [value]="item">{{item}}</option>
                </select>
              </div>
            </div>
            <div class="field" [ngClass]="(mostrarCharts)?'disabled' : ''">
              <label class='label light-blue'>Fecha</label>
              <div class="ui input" color="accent" appearance="outline">
                <input formControlName="fecha" type='date' placeholder="29/03/2017">
              </div>
            </div>
            <div class="field " [ngClass]="(mostrarCharts)?'disabled' : ''">
              <label class='label light-blue'>N° de Equipo</label>
              <div class="ui input">
                <select class="ui selection dropdown" formControlName="equipo">
                  <option value="">Seleccione:N° de Equipo </option>
                  <option *ngFor="let equipo of  equipoOtFilters" [value]="equipo">{{equipo}}
                  </option>
                </select>
              </div>

            </div>
          </form>
        </div>
        <div class="extra content" [ngClass]="(mostrarCharts)?'disabled' : ''">
          <button class="ui right labeled icon button button-filter primary" [disabled]='formDatos.invalid'
            (click)="aplicar()">
            <i class=" check icon"></i>
            APLICAR
          </button>
          <button class="ui right labeled icon button button-filter primary" (click)="limpiar()">
            <i class="trash alternate
                        icon"></i>
            LIMPIAR
          </button>
        </div>
      </div>
    </div>
    <div class="twelve wide column " style="justify-content:center !important;">
      <div class="ui warning message" *ngIf='listRegistros.length === 0 && !spinner  && !mostrarCharts'>
        <i class="close icon"></i>
        <div class="header">
          No se encontraron registros
        </div>
      </div>
      <table style='margin:auto;' class="ui celled selectable table" *ngIf='mostrarLista && listRegistros.length> 0'>
        <thead>
          <tr>

            <th class="center aligned">Fecha</th>
            <th class="center aligned">N° OT</th>
            <th class="center aligned">Tipo</th>
            <th class="center aligned">Descripción</th>
            <th class="center aligned">Estado</th>
            <th class="center aligned">UT</th>
            <th class="center aligned">N° de Equipo</th>
            <th class="center aligned">Avisos</th>
            <th class="center aligned"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let item of listRegistros;index as index ;'>
            <td class="center aligned">
              <!--<p *ngIf="item.When">{{item.When | date:'dd/MM/yyyy'}}</p>-->
              {{item.whenStr}}
            </td>
            <td class="center aligned">
              <p *ngIf="item.Status.OT">{{item.Status.OT.Observed}}</p>
            </td>
            <td class="center aligned">
              <p *ngIf="item.Status.Type">{{item.Status.Type}}</p>
            </td>
            <td class="center aligned">
              <p *ngIf="item.Description">{{item.Description}}</p>
            </td>
            <td class="center aligned">
              <p>
                {{item.state}}
              </p>
            </td>
            <td class="center aligned">
              <p *ngIf="item.Status.UT">{{item.Status.UT.Observed}}</p>
            </td>
            <td class="center aligned">
              <p *ngIf="item.Status.SNEquipo">{{item.Status.SNEquipo.Observed}}</p>
            </td>
            <td class="center aligned">
              <p>{{item.cantidadDeAvisoDeMantenimiento}}</p>
            </td>
            <td class="center aligned">
              <div class="ui vertical animated button" tabindex="0" (click)="detalle(item,item.Protocol_Id,item.Status.Type)">
                <div class="hidden content">Ver </div>
                <div class="visible content">
                  <i class="arrow right icon"></i>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="ui link charts" *ngIf='mostrarCharts'>
        <mat-card>
          <div class="scroll">
            <app-bar #graficos [filtros]="filtros"></app-bar>
          </div>

        </mat-card>


      </div>
      <div class="ui grid" *ngIf='pages_total >0 && mostrarLista'>
        <div class="column centered row paginacion">
          <app-paginator #paginacion [pages_total]='pages_total' (eventEmmiterChangePage)='changePage($event)'>
          </app-paginator>
        </div>
      </div>
    </div>
  </div>
</div>