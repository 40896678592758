import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient, } from '@angular/common/http';
import { Login } from 'src/app/shared/models/login';
import { CookieService } from 'ngx-cookie-service';
import { UsuarioService } from '../services/usuario/usuario.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,private cookies: CookieService,private usuarioServices:UsuarioService) {

  }


  login(login: Login): Observable<any> {
    //http://192.168.0.3:8080/loginLDAP
    //http://tgn.admin.candoit.com.ar/loginLDAP
    return this.http.post<any>(`${environment.urlBack}/loginLDAP`, login, { observe: 'events' }).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }


  Token(): Observable<any> {
    let login = new Login(environment.user.username, environment.user.password);
    return this.http.post<any>(`${environment.apiUrl}/api/login`, login).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  authenticate(): Observable<any> {
    let login = new Login(environment.userApi.username, environment.userApi.password);
    return this.http.post<any>(`${environment.urlBack}/authenticate`, login).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  logout() {
    this.cookies.deleteAll();
    localStorage.removeItem('filtroActividades');
    localStorage.removeItem('user');
    localStorage.removeItem('ordenCampoPendientes');
    localStorage.removeItem('ordenTipoPendientes');
    localStorage.removeItem('ordenTipoProcesados');
    localStorage.removeItem('ordenCampoProcesados');
  }

}
