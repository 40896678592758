import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { DetalleComponent } from './pages/detalle/detalle.component';
import { TableResultsComponent } from './components/table-results/table-results.component';
import { ModalObservacionesComponent } from './components/modal-observaciones/modal-observaciones.component';
import { DetalleInstrumentalesComponent } from './pages/detalle-instrumentales/detalle-instrumentales.component';
import { BancoDeBateriasComponent } from './pages/banco-de-baterias/banco-de-baterias.component';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations:[  PreguntasComponent, DetalleComponent, TableResultsComponent, ModalObservacionesComponent, DetalleInstrumentalesComponent, BancoDeBateriasComponent],
  imports:[ BrowserModule,CommonModule, RouterModule,AngularMaterialModule,SharedModule],
  exports:[]
})
export class AdministracionModule { }
