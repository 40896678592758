import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { GalleryModalComponent } from 'src/app/shared/components/gallery-modal/gallery-modal.component';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { ModelComentarioComponent } from 'src/app/shared/components/model-comentario/model-comentario.component';
import { ModalObservacionesComponent } from '../../components/modal-observaciones/modal-observaciones.component';
declare var $: any;

@Component({
  selector: 'app-detalle-instrumentales',
  templateUrl: './detalle-instrumentales.component.html',
  styleUrls: ['./detalle-instrumentales.component.scss']
})
export class DetalleInstrumentalesComponent implements OnInit {
  numero_ot: any;
  fecha: any;
  planta: any;
  token: any;
  audito: any;
  equipo: any;
  ut: any;
  descripcion: string;
  id: string;
  state: string;
  url = environment.urlBack;
  missionsIgnored = []
  actividades: any[] = []
  mensajes: any[] = []
  punajes: any[] = [];
  resumen: any[] = [];
  repuestosFueraDelPlan: any[] = [];
  repuestosPlanificados: any[] = [];
  definiciones: any[] = [];
  baterias: any[] = [];
  idsSubtabla = 0
  cargaInicialStatus: any;
  instrucionesItems = []
  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private rutaActiva: ActivatedRoute,
    private service: Service,
    private serviceApi: ApiServerService,
    public dialog: MatDialog,

  ) {
    this.initializeData();
  }

  initializeData(): void {
    $("#spinner-load").css("display", "block");

    this.descripcion = this.rutaActiva.snapshot.params.descripcion;
    this.id = this.rutaActiva.snapshot.params.id;
    this.state = this.rutaActiva.snapshot.params.state;

    //obtenemos las definiciones
    this.service.getDefinitions(this.descripcion).subscribe(res => {
      this.definiciones = (res.data.Input_Ids)
    })

    //obtenemos datos destacados de la boroscopia
    this.serviceApi.getBoroscopiaDestacadas('?ot=' + this.id).subscribe(res => {
      if (res.status_code !== '404') {
        this.resumen = res.filter(d => d.preguntas.length > 0);
      }
    })

    //obtenemos datos del detalle
    this.service.getByProtocolAndOt(this.descripcion, this.id).subscribe(results => {
      if (results) {
        this.cargaInicialStatus = results.Status;
        this.fecha = results.When;
        this.numero_ot = results.Status.OT.Observed;
        this.ut = results.Status.UT.Observed;
        let planta = results.Status.UT.Observed;
        this.planta = planta.substring(0, 3);
        this.equipo = new Number(results.Status.SNEquipo.Observed);
        this.audito = results.Who[0].username;
      }

      this.token = this.usuarioService.getToken();


      this.service.getMissionId(this.descripcion, this.id).subscribe(missionId => {

        this.service.getActivities(missionId).subscribe(actividades => {
          this.mensajes = this.filtrarUltimas(actividades.data)
          this.serviceApi.getComentarios(this.id).subscribe(comentarios => {

            let actividades: any = this.groupByActividad(this.mensajes);
            actividades.sort((a, b) => b.sec - a.sec);
            actividades = actividades.filter(d => !d.key.includes('Mtto') && !d.key.includes('mtto'));
            actividades.forEach(actividad => {
              if (actividad.key.includes("Banco De Baterias")) {

                let PruebaDeDescargaOn = actividad.results.find(d => d.Instruction_Id == 'PruebaDeDescargaOn');
                let PruebaDeDescargaMed0 = actividad.results.find(d => d.Instruction_Id == 'PruebaDeDescargaMed0');
                let PruebaDeDescargaMed1 = actividad.results.find(d => d.Instruction_Id == 'PruebaDeDescargaMed1');
                let PruebaDeDescargaMed2 = actividad.results.find(d => d.Instruction_Id == 'PruebaDeDescargaMed2');

                actividad.results = actividad.results.filter(d => {
                  return d.Instruction_Id !== 'PruebaDeDescargaOn' &&
                    d.Instruction_Id !== 'PruebaDeDescargaMed0' &&
                    d.Instruction_Id !== 'PruebaDeDescargaMed1' &&
                    d.Instruction_Id !== 'PruebaDeDescargaMed2';
                });
                this.obtenerDatosBaterias(PruebaDeDescargaOn, PruebaDeDescargaMed0, PruebaDeDescargaMed1, PruebaDeDescargaMed2);
              } else {
                let items: any[] = []
                let fotos: any[] = []
                let observaciones: any[] = []
                let avisos: any[] = []
                let cantAvisos: any[] = []

                actividad.results.forEach(d => {

                  if (d.Instruction_Dsc != 'Carga Inicial') {
                    if (d.Instruction_Id.startsWith('Foto') && d.Status[d.Instruction_Id] && d.Status[d.Instruction_Id].Observed != 'item') {
                      fotos.unshift(d)
                    } else
                      if (d.Evidence_Type == "AvisoDeMantenimiento") {
                        observaciones.push(d)
                      } else/* if (d.Status && d.Status.TCAvisoMtto && d.Status.TCAvisoMtto.Observed && d.Status.TCAvisoMtto.Observed != 'N/A') {
                        console.log(d)
                        let respuesta: any = {};
                        if (d.Status[d.Instruction_Id]) {
                          respuesta = d.Status[d.Instruction_Id]
                          d.respuesta = respuesta.Observed;
                        }
                        if (respuesta.Observed !== 'N/A') avisos.unshift(d);
                      } else*/ {
                        this.instrucionesItems.push('Foto' + d.Instruction_Id)

                        let respuesta = d.Status[d.Instruction_Id];
                      
                        if (respuesta) {
                          if (respuesta.Evidence) {

                            //items con imagenes
                            d.respuesta = '-';
                            respuesta.id = d.Mission_Id;
                            d.fotos = respuesta;
                            items.unshift(d)

                          } else {
                            //items cuestionario y formulario
                            for (let keyValue in d.Status) {
                              if (!keyValue.includes('Tolerancia') && !keyValue.includes('Unidad') && !keyValue.includes('valor')) {
                               
                                if (this.definiciones[keyValue]!=null && this.definiciones[keyValue].Label.trim()!=='') {
                              
                                  d.label = this.definiciones[keyValue].Label;
                                } else {
                                  d.label = d.Instruction_Dsc;
                                }
                                d.label += this.textoNominal(keyValue, d);
                                d.red = this.definirTolerancia(keyValue, d)
                                d.respuesta=respuesta.Observed;
                              }
                            }

                            items.unshift(d)

                          }
                        } else {
                          const copiaObj = Object.assign({}, d);

                          d.respuesta = ''
                          copiaObj.subtabla = [];
                          let aux: any[] = [];


                          for (let keyValue in d.Status) {

                            if (!keyValue.includes('Tolerancia') && !keyValue.includes('Unidad') && !keyValue.includes('TCAviso')) {

                              let respuesta = d.Status[keyValue].Observed;

                              if (respuesta !== '') {
                                let nuevo: any = {}

                                if (this.definiciones[keyValue] != null && this.definiciones[keyValue].Label.trim() !== '') {
                               //   console.log("---- " + keyValue + " ----");
                               //   console.log(this.definiciones[keyValue])
                                  nuevo.label = this.definiciones[keyValue].Label;
                                } else {
                                  nuevo.label = this.formatearLabel(keyValue, respuesta);
                                }
                                nuevo.valor = respuesta;
                                nuevo.label = nuevo.label + this.textoNominal(keyValue, d);
                                nuevo.red = this.definirTolerancia(keyValue, d)
                                aux.push(nuevo)
                              }
                            }
                            if (keyValue.includes('TCAvisoMtto') && !keyValue.includes('DetalleTCAviso')) {
                              let respuesta = d.Status[keyValue].Observed;
                              if (respuesta == 'M1' || respuesta == 'M2') {
                                let aviso: any = {}
                                aviso.valor = respuesta;
                                aviso.red = true;
                                aviso.label = 'Aviso de mantenimiento';
                                cantAvisos.push(d.Instruction_Id)
                                aux.push(aviso)
                                let det = d.Status['Detalle' + keyValue];
                                if (det) {
                                  let detalle: any = {}
                                  detalle.red = true;
                                  detalle.label = 'Detalle aviso de mantenimiento';
                                  detalle.valor = det.Observed;
                                  aux.push(detalle)
                                }
                              }
                            }

                          }
                          d.subtabla = this.ordenarLabels(aux);

                          d.mostrarImagen = this.imagenExtra(d);

                          items.unshift(d)

                        }
                      }
                  }
                })


                let itemsFotos = fotos.filter(d => !this.instrucionesItems.includes(d.Instruction_Id))
                itemsFotos.forEach(f => {
                  f.fotos = f.Status[f.Instruction_Id]
                  f.fotos.id = f.Mission_Id;
                  items.push(f)
                })

                let item = {
                  items: this.getItems(items, cantAvisos, avisos, observaciones, fotos, comentarios.commentsDTOs),
                  actividad: actividad.key
                }

                if (actividad.key != "" && actividad.key && actividad.key !== 'Cierre y finalización del mtto') {
                  this.actividades.unshift(item)
                }
              }

            })

            $('#spinner-load').css("display", "none");
          });
        })

      });

    });
  }


  getItems(items: any, cantAvisos: any, avisos: any[], observaciones: any[], fotos: any[], comentarios: any): any {



    let group = this.groupByInstructionId(items);

    group.forEach((item: any) => {
      item.title = item.items[0].Instruction_Dsc ;
      item.Instruction_Id = item.items[0].Instruction_Id;
      item.avisos = [];
      item.cantAvisos = cantAvisos
      item.observaciones = observaciones.filter(d => d.Instruction_Id == item.key);
      item.fotos = fotos.filter(d => d.Instruction_Id == item.key);
      item.comentarios = comentarios;
      item.columnaFotos = true;
      item.items.forEach(d => {
        if (d.subtabla) d.Instruction_Dsc = ""
      })


    })
    avisos.forEach((item: any) => {
      item.title = item.Instruction_Dsc;
      item.avisos = avisos;
      item.observaciones = observaciones;
      item.fotos = fotos || [];
      item.comentarios = comentarios || [];
      item.items = [];
      item.columnaFotos = true;
      group.push(item)

    })

    return group;
  }

  groupByInstructionId(data: { Instruction_Id: number }[]) {
    const groupedData = data.reduce((items, item) => {
      const key = item.Instruction_Id;

      if (!items[key]) {
        items[key] = [];
      }

      items[key].push(item);

      return items;
    }, {});

    const formattedResult = Object.entries(groupedData).map(([key, items]) => ({
      key: key,
      items,
    }));

    return formattedResult;
  }

  transformString(cadena: string): string {
    const coincidencias = cadena.match(/[a-z]+|[A-Z][a-z]*|\d+/g) || [];

    let nuevaCadena = coincidencias.join(' ');
    if (/([A-Z])\s(?=[A-Z])/g.test(nuevaCadena)) { nuevaCadena = nuevaCadena.replace(/([A-Z])\s(?=[A-Z])/g, '$1'); }
    if (/T\s\d+$/.test(nuevaCadena)) { nuevaCadena = nuevaCadena.replace(/T\s/g, 'T'); }
    if (/Y(?!\s)/.test(nuevaCadena)) { nuevaCadena = nuevaCadena.replace(/Y/g, 'Y '); }
    nuevaCadena = nuevaCadena.toLowerCase();
    nuevaCadena = nuevaCadena.charAt(0).toUpperCase() + nuevaCadena.slice(1);
    return nuevaCadena;
  }



  imagenExtra(d) {
    const opcionesArray = ['VibracionesCojinetes'];
    for (const opcion of opcionesArray) {
      if (d.Instruction_Id.startsWith(opcion)) {
        return true;
      }
    }
    return false;
  }
  eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
  }
  reordenarActividades(array: any) {
    const orden = [];

    // Crear un mapa que asocie cada actividad con su posición en el nuevo orden
    const ordenMap = new Map();
    orden.forEach((elem, index) => {
      ordenMap.set(this.eliminarDiacriticos(elem), index);
    });

    // Ordenar el array original en función del mapa
    array.sort((a, b) => {
      const actividadA = this.eliminarDiacriticos(a.actividad);
      const actividadB = this.eliminarDiacriticos(b.actividad);

      const ordenA = ordenMap.get(actividadA);
      const ordenB = ordenMap.get(actividadB);

      return ordenA - ordenB;
    });

    return array;
  }

  filtrarUltimas(preguntas: any) {
    const map = new Map<string, any>();
    preguntas.forEach(obj => {
      const compositeKey = `${obj.Instruction_Id}_${obj.Evidence_Type}`;
      const existingObj = map.get(compositeKey);
      if (!existingObj || obj.When > existingObj.When) {
        map.set(compositeKey, obj);
      }
    });
    const result = Array.from(map.values());
    return result;
  }
  /**
    filtrarUltimasPreguntas(preguntas: any) {
      let preguntasFilter = []
      preguntas.forEach(e => {
        if (!preguntasFilter.find(d => d.Instruction_Dsc == e.Instruction_Dsc)) {
          preguntasFilter.push(e)
        }
      });
      return preguntasFilter;
    }
   */
  ordenarPreguntas(arr: any) {
    return arr.sort((x, y) => x.Instruction_Seq - y.Instruction_Seq);
  }

  preguntasAdicionales(pregunta: any, respuesta: any) {
    if (pregunta && respuesta) {
      const preguntaAdicional = pregunta.Status.PreguntaAdicional.Observed;

      if (preguntaAdicional !== '') {
        const data = {
          Activity_Dsc: pregunta.Activity_Dsc,
          Mission_Id: pregunta.Mission_Id,
          Protocol_Id: pregunta.Protocol_Id,
          Status: {
            PreguntaAdicional: {
              Text: preguntaAdicional,
              Observed: respuesta.Status,
            },
          },
          Instruction_Id: "PreguntaRespuestaAdicional",
          _id: pregunta._id,
        };

        return data;
      }
    }

    return null; // No se cumple la condición, no hay datos para devolver.
  }


  ngOnInit(): void {

  }
  obtenerDatosBaterias(PruebaDeDescargaOn: any, PruebaDeDescargaMed0: any, PruebaDeDescargaMed1: any, PruebaDeDescargaMed2: any) {
    for (let key in PruebaDeDescargaOn.Status) {
      let bateria: any = {}
      if (key.endsWith('on') || key.endsWith('On')) {
        key = key.slice(0, -2);
      }

      bateria.name = key.replace(/([A-Z])/g, ' $1').trim();
      bateria.name = bateria.name.replace(/\d/, ' $&');
      if (PruebaDeDescargaOn) {
        let datoOn = PruebaDeDescargaOn.Status[key + 'on'] ?? PruebaDeDescargaOn.Status[key + 'On'];
        bateria.on = datoOn.Observed;
      }
      if (PruebaDeDescargaMed0) {
        let dato0 = PruebaDeDescargaMed0.Status[key + 'med0'] ?? PruebaDeDescargaMed0.Status[key + '0'];
        bateria.cero = dato0.Observed;
      }
      if (PruebaDeDescargaMed1) {
        let dato1 = PruebaDeDescargaMed1.Status[key + 'med1'] ?? PruebaDeDescargaMed1.Status[key + '1'];
        bateria.uno = dato1.Observed;
      }
      if (PruebaDeDescargaMed2) {
        let dato2 = PruebaDeDescargaMed2.Status[key + 'med2'] ?? PruebaDeDescargaMed2.Status[key + '2'];
        bateria.dos = dato2.Observed;
      }

      this.baterias.push(bateria)
    }


  }
  ngAfterViewInit(): void {
    $('.ui.accordion').accordion();
  }
  goHome() {
    this.router.navigate(['/']);
  }
  getIdComentario(item) {

    let key = '';
    for (let key in item.Status) {
      key = key;
    }
    let instruccion = item['Instruction_Dsc']
    return item['Mission_Id'] + "-" + item['Activity_Dsc'] + "-" + key + '-' + instruccion.replace(/[^\w\s]/g, '');
    ;
  }

  verComentario(item, comentarios) {
    this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: this.numero_ot, _id: this.getIdComentario(item), comentarios: comentarios, type: "ver" } });
  }


  showObservaciones(data, titulo) {
    let info = []
    data.lista.forEach(item => {
      let observacion: any = {}
      observacion.gallery = [];
      let fotos = item.Evidence;
      observacion.descripcion = item.Observed;
      var i = 0;
      fotos.forEach(f => {
        const image = new GalleryImage();
        image.alt = f;
        image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
        image.position = i;
        observacion.gallery.push(image);
        i = i + 1;
      })
      info.push(observacion)

    })

    this.dialog.open(ModalObservacionesComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo, info: info } });
  }


  newComentario(item, comentarios) {
    let id = this.getIdComentario(item);
    const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { _id: id, nro: this.numero_ot, comentarios: comentarios, type: "crear" } });
    dialogRef.afterClosed().subscribe(() => {
      this.serviceApi.getComentario(id).subscribe(res => {
        let comentarios = res.commentsDTOs;
        item.comentarios = (comentarios) ? comentarios : [];
      })
    });
  }



  groupByActividad(data) {
    const key = "Activity_Dsc";
    return Object.entries(data.reduce((acc, cur) => {
      const actividad = cur[key];
      const sec = cur["Activity_Seq"];
      if (actividad !== "Carga Inicial") {
        if (!acc[actividad]) {
          acc[actividad] = {
            key: actividad.replace(/([A-Z])/g, ' $1'),
            sec: sec,
            results: [],
          };
        }

        let curCopy = Object.assign({}, cur);
        acc[actividad].results.push(curCopy);
      }


      return acc;
    }, {})).map(([key, value]) => value);
  }


  verImagen(array: any, position: any) {
    let gallery = [];
    var i = 0;
    array.forEach(f => {
      const image = new GalleryImage();
      image.alt = f;
      //image.imagen = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
      image.src = this.url + f.path;
      image.descripcion = f.comentario;
      image.position = i;
      gallery.push(image);
      i = i + 1;
    })
    this.dialog.open(GalleryModalComponent, { panelClass: 'custom-dialog-container', data: { titulo: '', gallery: gallery, position: position } });

  }
  activePanel(i) {

    if (document.getElementById("panel" + i).classList.contains("active")) {
      document.getElementById("panel" + i).classList.remove("active");
      document.getElementById("content" + i).classList.remove("active");
    } else {
      document.getElementById("panel" + i).classList.add("active");
      document.getElementById("content" + i).classList.add("active");
    }

  }
  formatearNumero(respuesta) {
    if (!isNaN(respuesta)) {
      if (respuesta % 1 !== 0) {
        respuesta = Number(respuesta).toFixed(2);
      }
    }
    return respuesta;
  }
  formatearLabel(keyValue, respuesta,) {
    const split = keyValue.split('_');
    let unidadParcial = (split[1] && !(/^\d+$/.test(split[1]))) ? " " + split[1] : '';
    let unidad = (unidadParcial.trim() == 'porcentaje') ? '%' : (unidadParcial.trim() == 'grados') ? "°" : unidadParcial;
    if (respuesta) respuesta = respuesta + unidad;
    if (!isNaN(respuesta)) respuesta = this.formatearNumero(respuesta);
    return this.transformString(split[0])
  }
  definirTolerancia(keyValue, d) {
    let keyTolerancia = "Tolerancia_" + keyValue.replace(/_.*/, '');
    let tolerancia = (d.Status[keyTolerancia]) ? d.Status[keyTolerancia].Observed : "";
    return (tolerancia == 'Fuera de rango esperado')
  }
  textoNominal(keyValue, d) {
    let keyValorNominal = keyValue.replace(/_.*/, '') + "_valor";
    let nominal = d.Status[keyValorNominal];
    return (nominal) ? '. Valor esperado : ' + nominal.Observed : '';
  }
  ordenarLabels(aux) {
    const fraseComun = aux.length > 0 ? aux[0].label.match(/^.*?\D+/) : null;
    const todosTienenFraseComun = fraseComun && aux.every(item => item.label.startsWith(fraseComun[0]));

    if (todosTienenFraseComun) {
      aux.sort((a, b) => {
        let indexa = a.label;
        let indexb = b.label;
        let numerosa = indexa.replace(/[^0-9]/g, '');
        let numerosb = indexb.replace(/[^0-9]/g, '');

        if (numerosa && numerosb) {
          indexa = parseFloat(numerosa);
          indexb = parseFloat(numerosb);
        }
        if (indexa < indexb) {
          return -1;
        }
        if (indexa > indexb) {
          return 1;
        }
        return 0;
      });
      return aux;
    }
  return aux;
  }
}
