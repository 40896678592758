<div class="ui container">
    <app-header-component [protocol]="descripcion" [botonBoroscopia]="enabledBoroscopia"[ot]="numero_ot" [title]='descripcion' [estado]='state'>
    </app-header-component>
    <div id="">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS PRELIMINARES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label '> OT:<span class='negrita'>{{numero_ot}}</span></td>
                        <td class='label'>N° de Equipo:<span class='negrita'>{{equipo}}</span></td>

                    </tr>
                    <tr>
                        <td class='label'>UT:<span class='negrita'>{{planta}}</span></td>
                        <td class='label'> Fecha:<span class='negrita'>{{fecha|
                                date:'dd/MM/yyyy'}}</span></td>
                    </tr>
                    <tr>
                        <td class='label'>Planta:<span class='negrita'>{{planta }}</span></td>
                        <td class='label'>Ejecutante:<span class='negrita'>{{ejecutante}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ui divider no-pdf-flex"></div>

        <div *ngFor="let item of actividades;index as i" class="container-protocol no-pdf">
            <div class="ui grid " style="margin-top: 0.5em;">

                <div class="sixteen wide column" *ngIf="item != 'repuestos'">

                    <div class="ui styled accordion " style="margin-top: 0.5em;">
                        <div [id]="'panel'+item.actividad+i" class="title " (click)="activePanel(item.actividad+i)">
                            <i class="dropdown icon"></i>
                            {{item.actividad}}
                            <i *ngIf="item.avisos.length" class=" exclamation triangle icon font-red pointer"> </i>
                        </div>
                        <div [id]="'content'+item.actividad+i" class="content ">
                            <app-table-results *ngIf="item.items" [nro]="numero_ot" [data]="item"></app-table-results>
                        </div>
                    </div>
                </div>
                <div class="sixteen wide column" *ngIf="item == 'repuestos'">

                    <div *ngIf="repuestosPlanificados.length>0" class="ui styled accordion " style="margin-top: 0.5em;">
                        <div [id]="'panelrepuestosPlanificados'" class="title "
                            (click)="activePanel('repuestosPlanificados')">
                            <i class="dropdown icon"></i>
                            Repuestos planificados
                        </div>
                        <div [id]="'contentrepuestosPlanificados'" class="content ">

                            <table class="ui single line table">
                                <thead>
                                    <tr>
                                        <th class="">Código SAP</th>
                                        <th class="">Observación</th>
                                        <th class="">Cantidad planificada</th>
                                        <th class="">Cantidad real</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let respuesto of repuestosPlanificados">

                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.codigo}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.descripcion}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.cantPlanificada}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.cantReal}}
                                        </td>


                                    </tr>
                                </tbody>
                            </table>

                        </div>


                    </div>
                    <div *ngIf="repuestosFueraDelPlan.length>0" class="ui styled accordion " style="margin-top: 0.5em;">
                        <div [id]="'panelrepuestosFueraDelPlan'" class="title "
                            (click)="activePanel('repuestosFueraDelPlan')">
                            <i class="dropdown icon"></i>
                            Repuestos fuera del plan
                        </div>
                        <div [id]="'contentrepuestosFueraDelPlan'" class="content ">

                            <table class="ui single line table">
                                <thead>
                                    <tr>
                                        <th class="">Código SAP</th>
                                        <th class="">Observación</th>
                                        <th class="">Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let respuesto of repuestosFueraDelPlan">

                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.codigo}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.descripcion}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{respuesto.cant}}
                                        </td>


                                    </tr>
                                </tbody>
                            </table>

                        </div>


                    </div>

                </div>
            </div>
        </div>
        <div class="no-pdf " *ngIf="resumen.length>0">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="resumen.length>0">
                <div class="sixteen wide column">
                    <div class="ui styled accordion " style="margin-top: 0.5em;">
                        <div [id]="'panelResumenBoroscopia'" class="title " (click)="activePanel('ResumenBoroscopia')">
                            <i class="dropdown icon"></i>
                            Resumen Boroscopia
                        </div>
                        <div [id]="'contentResumenBoroscopia'" class="content ">
                            <div *ngFor="let punto of resumen">

                                <h1 class="title ">
                                    {{punto.titulo}} - {{punto.description}}
                                </h1>
                                <table class="ui single line table">
                                    <thead>
                                        <tr>
                                            <th class="">Inspección para</th>
                                            <th class="">Resultados</th>
                                            <th class=" ">Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of punto.preguntas">
                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                {{p.texto}}
                                            </td>

                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.respuesta">{{p.respuesta.description}}</span>
                                                <span *ngIf="!p.respuesta">-</span>
                                            </td>
                                            <td colspan="2" style="width: 30% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.comentarios">{{p.comentarios.description}}</span>
                                                <span *ngIf="!p.comentarios">-</span>

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="4" class="center aligned"
                                                style="width: 70% !important;white-space: pre-wrap;">
                                                <div [style.flex-wrap]="'wrap'" [style.display]="'flex'" *ngIf="url">
                                                    <div [style.width]="'150px'"
                                                        *ngFor="let imagen of  punto.imagenes; index as i">
                                                        <div [style.margin]="'5px'">
                                                            <img [style.width]="'100%'"
                                                                (click)="verImagen(punto.imagenes, i)"
                                                                [src]="url+imagen.path" alt="">
                                                            <br>

                                                            <span>{{imagen.comentario}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>

    </div>
    <!--class="si-pdf"-->
    <div id="DATA-PDF" class="si-pdf">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS PRELIMINARES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label '> OT:<span class='negrita'>{{numero_ot}}</span></td>
                        <td class='label'>N° de Equipo:<span class='negrita'>{{equipo}}</span></td>

                    </tr>
                    <tr>
                        <td class='label'>UT:<span class='negrita'>{{planta}}</span></td>
                        <td class='label'> Fecha:<span class='negrita'>{{fecha|
                                date:'dd/MM/yyyy'}}</span></td>
                    </tr>
                    <tr>
                        <td class='label'>Planta:<span class='negrita'>{{planta }}</span></td>
                        <td class='label'>Ejecutante:<span class='negrita'>{{ejecutante}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngFor="let item of actividades;index as i" class="container-protocol si-pdf">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="actividades.length>0">
                <div class="sixteen wide column">
                    <div class="title ">
                        {{item.actividad}}
                    </div>
                    <div>
                        <app-table-results [nro]="numero_ot" [data]="item">
                        </app-table-results>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="resumen.length>0">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="resumen.length>0">
                <div class="sixteen wide column">
                    <div class=" " style="margin-top: 0.5em;">
                        <div class="title ">
                            Resumen Boroscopia
                        </div>
                        <div>
                            <div *ngFor="let punto of resumen">

                                <h1 class="title ">
                                    {{punto.titulo}} - {{punto.description}}
                                </h1>
                                <table class="ui single line table">
                                    <thead>
                                        <tr>
                                            <th class="">Inspección para</th>
                                            <th class="">Resultados</th>
                                            <th class=" ">Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of punto.preguntas">
                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                {{p.texto}}
                                            </td>

                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.respuesta">{{p.respuesta.description}}</span>
                                                <span *ngIf="!p.respuesta">-</span>
                                            </td>
                                            <td colspan="2" style="width: 30% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.comentarios">{{p.comentarios.description}}</span>
                                                <span *ngIf="!p.comentarios">-</span>

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="4" class="center aligned"
                                                style="width: 70% !important;white-space: pre-wrap;">
                                                <div [style.flex-wrap]="'wrap'" [style.display]="'flex'" *ngIf="url">
                                                    <div [style.width]="'150px'"
                                                        *ngFor="let imagen of  punto.imagenes; index as i">
                                                        <div [style.margin]="'5px'">
                                                            <img [style.width]="'100%'"
                                                                (click)="verImagen(punto.imagenes, i)"
                                                                [src]="url+imagen.path" alt="">
                                                            <br>

                                                            <span>{{imagen.comentario}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="repuestosPlanificados.length>0">
            <div class="ui grid " style="margin-top: 0.5em;">
                <div class="sixteen wide column">
                    <div class="ui   " style="margin-top: 0.5em;">
                        <div class="title ">
                            Repuestos planificados
                        </div>
                        <div>

                            <table class="ui single line table">
                                <thead>
                                    <tr>
                                        <th class="">Código SAP</th>
                                        <th class="">Observación</th>
                                        <th class="">Cantidad planificada</th>
                                        <th class="">Cantidad real</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of repuestosPlanificados">

                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.codigo}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.descripcion}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.cantPlanificada}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.cantReal}}
                                        </td>


                                    </tr>
                                </tbody>
                            </table>

                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="repuestosFueraDelPlan.length>0">
            <div class="ui grid " style="margin-top: 0.5em;">
                <div class="sixteen wide column">
                    <div class="ui " style="margin-top: 0.5em;">
                        <div class="title ">
                            Repuestos fuera del plan
                        </div>
                        <div>

                            <table class="ui single line table">
                                <thead>
                                    <tr>
                                        <th class="">Código SAP</th>
                                        <th class="">Observación</th>
                                        <th class="">Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of repuestosFueraDelPlan">

                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.codigo}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.descripcion}}
                                        </td>
                                        <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                            {{item.cant}}
                                        </td>


                                    </tr>
                                </tbody>
                            </table>

                        </div>


                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="ui grid">
        <div class="extra content">
            <button style="margin-top: 3em;" class="ui right labeled icon button button-filter primary"
                (click)="goHome()">
                <i class="home
                          icon"></i>
                Volver
            </button>
        </div>
    </div>
</div>