import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { FiltroActividades } from 'src/app/shared/models/filtroActividades';
import { Login } from 'src/app/shared/models/login';
import { AuthService } from '../../../../core/authentication/auth.service';
import { RedirectService } from '../../../../core/services/redirect/redirect.service';
declare var $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(private snackBar: MatSnackBar, private router: Router, private usuarioServices: UsuarioService, private authService: AuthService, private form: FormBuilder, private redirectService: RedirectService) {
    $('#spinner-load').css("display", "none");

    this.loginForm = this.form.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required)
    });
  }
  ngOnInit() {
  }
  onSubmit() {
    const pass = this.loginForm.get('password').value;
    const user = this.loginForm.get('username').value;
    let login = new Login(user, pass);
    this.authService.login(login)
      .subscribe(
        success => {
          if (success.body) {

            if (success.body.status_code == '400') {
              this.snackBar.open('Ocurrió un error al conectar con el servidor, vuelva a intentar más tarde.', 'X');
            } else
              if (success.body.user_ad == "true") {
                    this.usuarioServices.setData(success.body)
                    let filtros = new FiltroActividades();
                    localStorage.setItem("filtroActividades", JSON.stringify(filtros))
                    this.authService.Token().subscribe(token => {
                      this.usuarioServices.setToken(token.token);
                      this.authService.authenticate().subscribe(authenticate =>{
                        this.usuarioServices.setTokenApi(authenticate.token);
                        this.router.navigate(['/']);
                      })
                    });
               
              } else {
                this.snackBar.open('Usuario o contraseña incorrectos', 'X');
              }
          }
        },
        err => {
          this.snackBar.open('Ocurrió un error al conectar con el servidor, vuelva a intentar más tarde.', 'X');
        });
  }

  getUsename() {
    return this.loginForm.get('username');
  }

  getPassword() {
    return this.loginForm.get('password');
  }


  mostrarPass() {
    var tipo: any = document.getElementById("password");
    if (tipo.type == "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }


}
