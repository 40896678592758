import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { environment } from 'src/environments/environment';

declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user='Usuario';
  @Output() sidenavToggle = new EventEmitter<void>();
  constructor(private authService: AuthService,private usuarioServices:UsuarioService, private router: Router) {
 
  
  }
  isLogged(){
    return this.usuarioServices.isLoggedIn();
  }


  ngOnInit() {
  
    this.authService.Token().subscribe(token=>{
      this.usuarioServices.setToken(token.token);
    });

    $('.ui.dropdown').dropdown();
  }

  ngAfterViewInit(): void {
    $('.ui.dropdown').dropdown();
  }
  ngAfterContentChecked(){
    if(this.usuarioServices.getUsername()!= 'undefined'){
      this.user=this.usuarioServices.getUsername();
    }
  }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
 

  puntajes() {
    this.router.navigate(['administrar-puntajes'])
  }
  logout() {
    this.usuarioServices.clear();
    var url=window.location.href;
    if(url == environment.urlAssets || url == environment.urlAssets+'/' ||  url == environment.urlAssets+'/home' ){
     window.location.reload();
    }else{
      this.router.navigate(['/home'])

    }
    
  }

  login() {
    this.router.navigate(['/login'])
  }
}
