import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.scss']
})
export class HeaderComponentComponent implements OnInit {
  @Input() ot;
  @Input() protocol;
  @Input() botonBoroscopia = true;
  @Input() title;
  @Input() estado;
  @Input() score;
  @Input() botones = true;
  @Input() botonBB = false;
  @Input() botonDescargar;
  arrayIdPds: string[] = [];
  widthInicial = 295;
  options: any;
  html: string;
  @Output() accionDescargar: EventEmitter<boolean> = new EventEmitter<boolean>();
  id_component: string;
  mission_id: string;
  constructor(public snackBar: MatSnackBar, private router: Router, private routerActivated: ActivatedRoute, private usuarioServices: UsuarioService) {
    this.id_component = this.routerActivated.snapshot.params['id_componente'];
    this.mission_id = this.routerActivated.snapshot.params['mission_Id'];

  }

  ngOnInit(): void {
  }

  copyURL() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = document.location.href;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.mensaje('Link copiado!', '')
  }

  descargarPDF() {
    this.accionDescargar.emit(true);
    $('.dropdown.icon').hide();
    $('.title').addClass('active')
    $('.content').addClass('active')
    $("#spinner").css("display", "block");
    $('.no-pdf').addClass('none');
    $('.no-pdf-flex').addClass('none');
    $('.no-pdf-cell').addClass('none');
    $('.content.active > .container-panel').addClass('transition visible')


    this.html = (document.getElementById("DATA-PDF").innerHTML);
    this.html = this.html.replace('styled accordion', '')
    this.usuarioServices.generarPDF2(this.html, this.title, this.estado, this.score).subscribe(data => {
      this.accionDescargar.emit(false);
      $('.dropdown.icon').show();
      $("#spinner").css("display", "none");
      $('.no-pdf').removeClass('none');
      $('.no-pdf-flex').removeClass('none');
      $('.no-pdf-cell').removeClass('none');
      $('.title').removeClass('active')
      $('.content').removeClass('active')
      this.mensaje('PDF listo!', '');
      this.downloadFile(data.body, this.title + '.pdf')
    });


  }
  downloadFile(blob, fileName) {
    const link = document.createElement('a');
    this.blobToBase64(blob, (base64) => {
      link.href = base64;
      link.download = fileName;
      link.click();
      window.close()

    });
  };

  blobToBase64(blob, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      callback(base64);
    };
    reader.readAsDataURL(blob);

  };


  mensaje(message, action) {
    this.snackBar.open(message, action, {
      duration: 6500,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['gray-snackbar'],
    });
  }

}


