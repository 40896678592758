import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class Service {

  token: string;
  constructor(private http: HttpClient, private usuarioServices: UsuarioService) {
    this.token = this.usuarioServices.getToken();
  }
 
  getDefinitions(protocolName: any) {
    return this.http.get<any>(`${environment.apiUrl}/api/protocol/definitions?protocolName=${protocolName}&token=${this.token}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  getRepuestosFueraDelPlan(missionId: any) {
    let query = `api/results?orderBy={"When":-1}&q={"Mission_Id":"${missionId}","Activity_Id":"RepuestosFueraDelPlan"}`;

    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  getDatosResponsable(missionId: any) {
    let query = `api/results?orderBy={"When":-1}&q={"Mission_Id":"${missionId}","Instruction_Id":"DatosResponsable"}`;

    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => res.data[0])

    );
  }
  getMissionIgnored(nro: any) {
    return this.http.get(`${environment.apiUrl}/api/results?q={"Activity_Dsc": "Mission Ignored"}`).pipe(
      map((isAlive: any) => {
        return isAlive;
      })
    );
  }
  //nuevo
  getMissionId(descripcion, data) {
    var query = '';
    descripcion = descripcion.replaceAll(' ', '_')
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Activity_Id":"Carga Inicial","Protocol_Id":"${descripcion}","Status.OT.Observed":"${data}"}`)
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => {
        return res.data[0].Mission_Id;
      })
    );
  }

  getActivities(missionId) {
    var query = '';
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Mission_Id":"${missionId}"}`)

    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => {
        return res;
      })
    );
  }



  getByProtocolAndOt(protocolo, ot) {
    protocolo = protocolo.replaceAll(' ', '_')
    var query = '';
    query = query.concat(`api/results?orderBy={"When":-1}&q={"Protocol_Id": "${protocolo}", "Status.OT.Observed": "${ot}"}`)
    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => res.data[0])
    );
  }
  getByOt(ot) {
    var query = '';
    query = query.concat(`api/results?orderBy={"When":-1}&q={ "Status.OT.Observed": "${ot}"}`)

    return this.http.get<any>(`${environment.apiUrl}/${query}&token=${this.token}`).pipe(
      map((res: any) => res.data[0])
    );
  }











}
