<div class="ui grid">
    <div class="six  wide column ">
        <h3 class='title-style mt-3'>{{title}}</h3>
        <h2 *ngIf='estado' class='negrita ' style='margin-top: 0px!important;margin-left: 0px!important;'>
            Estado:
            <span class='font-gray' style='margin-top: 0px!important;padding-left: 5px;'>{{estado}}</span>
        </h2>
    </div>
    <div class="ten wide column right aligned  no-pdf" id='botones' *ngIf="botones">
        <button id='b1' *ngIf='!id_component && botonBoroscopia' class="ui right labeled icon button  primary no-pdf"
            [routerLink]="'/boroscopia/'+title+'-'+ estado +'/'+ot" style='vertical-align:inherit;margin-top:1.5em;'>
            <i class="plus icon pointer"></i>

            BOROSCOPIA
        </button>

        <button [disabled]='botonDescargar' id='b2' *ngIf='!id_component '
            class="ui right labeled icon button  primary no-pdf" (click)="descargarPDF()"
            style='vertical-align:inherit;margin-top:1.5em;'>
            <i class="download icon pointer"></i>

            DESCARGAR PDF

        </button>

        <button id='b3' class="ui right labeled icon button  primary no-pdf" id='copiarLink' (click)="copyURL()"
            style='vertical-align:inherit;margin-top:1.5em;'>
            <i class="clone outline pointer
                icon"></i>
            COPIAR LINK

        </button>

        <button id='b1' *ngIf='botonBB' class="ui right labeled icon button  primary no-pdf"
            [routerLink]="'/banco-baterias/'+ot" style='vertical-align:inherit;margin-top:1.5em;'>
            <i class="plus icon pointer"></i>
            BANCO DE BATERIAS
        </button>
    </div>



</div>

<div class="ui divider no-pdf-flex"></div>