<div mat-dialog-content>
  <p [style.color]="'#2185d0'" [style.font-size]="'20px'">{{ data.message1 }}</p>
  <span>{{ data.additionalData }}</span>
</div>
<div mat-dialog-actions>
 
  <button class="ui primary button"(click)="onYesClick()" >
    Confirmar
  </button>
  <button class="ui button" (click)="onNoClick()">
    Cancelar
  </button>
</div>