export class FiltroActividades{
    numero:number;
    planta:number;
    fecha:Date;
    tipo:string;
    descripcion:string;
    equipo:string;
    estado:String;
    max=100;
    min=0;
    anio:string;
}