import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { Descripcion } from 'src/app/shared/models/descripciones';
import { FiltroActividades } from 'src/app/shared/models/filtroActividades';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { BarComponent } from '../../components/bar/bar.component';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  spinner = true;
  color: ThemePalette = 'primary';
  mode: ProgressBarMode = 'determinate';
  value = 50;
  bufferValue = 75;
  from = 0;
  to = 100;
  listRegistros: any[] = [];
  listFiltrar: any[] = [];
  page=1;
  estados: any[] = [];
  formDatos: FormGroup;
  descripciones: any[] = [];
  anios: any[] = [];
  mostrarLista = true;
  mostrarCharts = false;
  registros: any[] = [];
  datosPreliminares: any[] = [];
  datosCargaInicial: any[] = [];
  total: number;
  plantas: any[] = [];
  tipos: any[] = [];
  numeroOtFilters: any[] = [];
  equipoOtFilters: any[] = [];
  @ViewChild("graficos") graficos: BarComponent;
  filtrosCompletados = false;
  filterDescripcion: Descripcion;
  filtros: FiltroActividades;
  totalResults: any[] = [];
  pages_total=0;
  constructor(private form: FormBuilder, private router: Router, private services: ApiServerService, private servicesUsuario: UsuarioService) {
    this.estados.push("Finalizado");
    this.estados.push("En proceso");
    this.tipos.push("Mecánico");
    this.tipos.push("Instrumental");
    $('#spinner-load').css("display", "block");

    let filtros = localStorage.getItem('filtroActividades')
    if (!filtros || filtros == '"null"' || filtros == 'null' || filtros == null) {
      this.filtros = new FiltroActividades();
    } else {
      this.filtros = JSON.parse(filtros);

    }

    this.from = this.filtros.min;
    this.to = this.filtros.max;
    this.formDatos = this.form.group({
      tipo: [(this.filtros.tipo) ? this.filtros.tipo : null],
      numero: [(this.filtros.numero) ? this.filtros.numero : null],
      planta: [(this.filtros.planta) ? this.filtros.planta : null],
      descripcion: [(this.filtros.descripcion) ? this.filtros.descripcion : null],
      estado: [(this.filtros.estado) ? this.filtros.estado : null],
      equipo: [(this.filtros.equipo) ? this.filtros.equipo : null],
      fecha: [(this.filtros.fecha) ? this.filtros.fecha : null],
      anio: [(this.filtros.anio) ? this.filtros.anio : null],
    }); 
    this.cargarLista()
  }



  ngOnInit(): void {

  }

  ngAfterViewInit() {


    $('.ui.dropdown').dropdown({
      "clearable": true
    });

    $('.ui.selection.dropdown').dropdown({
      "clearable": true
    });
  }

  cargarLista() {
    $('#spinner-load').css("display", "block");
    this.services.getAll(this.page, this.filtros, 11, []).subscribe(res => {
      this.numeroOtFilters = res.otList;
      this.descripciones = res.protocolList;
      this.plantas = res.utList;
      this.equipoOtFilters=res.equipoList;
      this.anios = res.aniosSet;
      this.pages_total = res.totalPages;
      this.listRegistros = res.data;
      this.listRegistros.forEach(d=>{
        d.Status.SNEquipo.Observed=new Number(d.Status.SNEquipo.Observed);
      })
      this.spinner = false;
      $('#spinner-load').css("display", "none");
    })
  }
  completarFiltros() {
    this.totalResults.forEach(d => {
      let planta = d.Status.UT.Observed;
      let ot = d.Status.OT.Observed;
      let equipo = d.Status.SNEquipo.Observed;
      let procotolo = d.Protocol_Id;
      let anio = (new Date(d.When)).getFullYear();
      if (!this.plantas.includes(planta)) {
        this.plantas.push(planta);
      }
      if (!this.numeroOtFilters.includes(ot)) {
        this.numeroOtFilters.push(ot);
      }
      if (!this.equipoOtFilters.includes(equipo)) {
        this.equipoOtFilters.push(equipo);
      }
      if (!this.descripciones.includes(procotolo)) {
        this.descripciones.push(procotolo);
      }

      if (!this.anios.includes(anio)) {
        this.anios.push(anio);
      }
    })
    this.filtrosCompletados = true;

  }


  detalle(item, descripcion,tipo) {
    if (descripcion == "Boroscopia") {
      this.router.navigate(["boroscopia/" + descripcion + "/" + item.Status.OT.Observed]);
    } else {
      if(tipo=='Instrumental'){
        this.router.navigate(["detalle-instrumentales/" + item.Status.OT.Observed + "/" + descripcion + "/" + item.state]);

      }else{
        this.router.navigate(["detalle-mecanicos/" + item.Status.OT.Observed + "/" + descripcion + "/" + item.state]);

      }
    }
  }


  charts() {
    this.mostrarCharts = true;
    this.mostrarLista = false;
  }
  list() {
    this.mostrarCharts = false;
    this.mostrarLista = true;
  }
  limpiar() {
    this.page=1;
    this.filtros = null;
    this.formDatos.controls['fecha'].setValue(null)
    this.formDatos.controls['descripcion'].setValue(null)
    this.formDatos.controls['tipo'].setValue(null)
    this.formDatos.controls['estado'].setValue(null)
    this.formDatos.controls['equipo'].setValue(null)
    this.formDatos.controls['numero'].setValue(null)
    this.formDatos.controls['planta'].setValue(null)
    this.formDatos.controls['anio'].setValue(null)

    this.page = 1;

    this.filtros = new FiltroActividades();
    this.filtros.planta = this.formDatos.get('planta').value;
    this.filtros.numero = this.formDatos.get('numero').value;
    this.filtros.tipo = this.formDatos.get('tipo').value;
    if (this.formDatos.get('fecha').value) {
      this.filtros.fecha = this.formDatos.get('fecha').value;
    } else {
      this.filtros.fecha = null;
    }


    this.filtros.descripcion = (this.formDatos.get('descripcion').value);
    this.filtros.tipo = (this.formDatos.get('tipo').value);
    this.filtros.estado = this.formDatos.get('estado').value;
    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))

    $('.ui.dropdown').dropdown('restore defaults');
    if (this.graficos) {
      this.graficos.graficar();
    }
    this.cargarLista()
  }

  changePage(number_page: number) {
    this.page = number_page;
    this.cargarLista();
  }

  aplicar() {
    this.page=1;
    this.filtros = new FiltroActividades();
    this.filtros.planta = this.formDatos.get('planta').value;
    this.filtros.tipo = this.formDatos.get('tipo').value;
    this.filtros.numero = this.formDatos.get('numero').value;
    this.filtros.equipo = this.formDatos.get('equipo').value;
    if (this.formDatos.get('fecha').value) {
      this.filtros.fecha = this.formDatos.get('fecha').value;
    } else {
      this.filtros.fecha = null;
    }
    this.filtros.anio = this.formDatos.get('anio').value;

    this.filtros.descripcion = (this.formDatos.get('descripcion').value);
    this.filtros.estado = this.formDatos.get('estado').value;
    this.filtros.max = this.to;
    this.filtros.min = this.from;
    localStorage.setItem('filtroActividades', JSON.stringify(this.filtros))
    /*if (this.graficos) {
      this.graficos.graficar();
    }*/

    $('#spinner-load').css("display", "block");

    this.cargarLista()


  }

  onChangeEvent(event) {
    if (event.to != undefined) {
      this.to = event.to
    }
    if (event.from != undefined) {
      this.from = event.from
    }
  }



  cumpleConFiltros(registro) {
    let cumple = true;

    if (this.filtros.descripcion && this.filtros.descripcion != registro.Protocol_Id) {
      cumple = false;
    }
    if (this.filtros.numero && this.filtros.numero != registro.Status.OT.Observed) {
      cumple = false;
    }
    if (this.filtros.equipo && this.filtros.equipo != registro.Status.SNEquipo.Observed) {
      cumple = false;
    }
    if (this.filtros.planta && this.filtros.planta != registro.Status.UT.Observed) {

      cumple = false;
    }
    if (this.filtros.estado && this.filtros.estado != registro.state) {
      cumple = false;
    }
    if (this.filtros.tipo && this.filtros.tipo != registro.Status.Type) {
      cumple = false;
    }

    let date = new Date(registro.When);
    let dateFiltro = new Date(this.filtros.fecha);
    if (this.filtros.fecha && !this.compararFechasFitros(date, dateFiltro)) {
      cumple = false;
    }

    if (registro.state == "Finalizado" && !this.filtroPorPuntaje(registro.score)) {
      cumple = false;


    }


    return cumple;
  }
  compararFechasFitros(f1, f2) {
    if (f1.getFullYear() == f2.getFullYear()
      && f1.getMonth() == f2.getMonth()
      && f1.getDate() == f2.getDate() + 1
    ) {
      return true;
    }
    return false;
  }
  filtroPorPuntaje(puntaje) {

    if (this.filtros.min == this.filtros.max) {
      if (puntaje == this.filtros.min) {
        return true;
      }
    } else {
      if (puntaje >= this.filtros.min && puntaje <= this.filtros.max) {
        return true;
      }
    }





    return false;
  }
}
