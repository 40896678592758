import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/components/shared.module';
import { BoroscopiaComponent } from './pages/boroscopia/boroscopia.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DndDirective } from 'src/app/shared/directives/dnd.directive';
import { FileUploadComponent } from './pages/file-upload/file-upload.component';




@NgModule({
  declarations: [BoroscopiaComponent, FileUploadComponent,DndDirective],
  imports:[ CommonModule, RouterModule,AngularMaterialModule,SharedModule,NgxFileDropModule],

})
export class BoroscopiaModule { }
