import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.scss']
})
export class PreguntasComponent implements OnInit {
  @Input() data;
  @Input() descriptionProtocol;
  @Input() descriptionActivity;
  
  @Output() changeEmitter = new EventEmitter();
  
  score = 0;
  constructor() { }

  ngOnInit(): void {
    this.score = this.data.score;
  }
  change() {
    this.changeEmitter.emit({ score: this.score, id: this.data.id ,actividad:this.descriptionActivity, prococolo:this.descriptionProtocol})
  }
  ngAfterContentInit() {
    $('.ui.selection.dropdown').dropdown();
  }
}
