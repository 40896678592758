<div class="container m-5">
  <div class="card">
    <div class="card-body" [ngClass]="{'dragover': isDragOver, 'not-dragover': !isDragOver}" id="drop-area" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
      <input type="file" #fileInput accept="image/*" (change)="onFilesSelected($event)" multiple style="display: none;" />
      <button (click)="fileInput.click()" class="ui right labeled icon button button-filter primary">
        <i class="upload icon"></i>
        {{boton}}
      </button>
    </div>
  </div>
</div>
