import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import ApexCharts from 'apexcharts/dist/apexcharts.common.js';

@Component({
  selector: 'app-banco-de-baterias',
  templateUrl: './banco-de-baterias.component.html',
  styleUrls: ['./banco-de-baterias.component.scss']
})
export class BancoDeBateriasComponent implements OnInit {
  datos: any;
  sumaTabla1 = {
    "descripcion": "Suma",
    "med_on": 0,
    "med_0": 0,
    "med_1": 0,
    "med_2": 0,
    "med_3": 0,
    "med_4": 0
  }
  sumaTabla2 = {
    "descripcion": "Suma",
    "med_on": 0,
    "med_0": 0,
    "med_1": 0,
    "med_2": 0,
    "med_3": 0,
    "med_4": 0
  }
  promedio = {
    "descripcion": "Promedio",
    "med_on": null,
    "med_0": null,
    "med_1": null,
    "med_2": null,
    "med_3": null,
    "med_4": null
  }
  columnsTable2 = []
  table1: any = []
  table2: any = []
  chartOptions: any = {
    series: [],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false
      },
      animations: {
        enabled: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },

    title: {
      text: ""
    },
    yaxis: [],
    xaxis: {}
  };
  ot: any;
  mensaje404 = false;
  constructor(private service: ApiServerService, private activatedRoute: ActivatedRoute) {
    this.ot = this.activatedRoute.snapshot.params.ot;


  }
  graficar() {
    let canvas = Array.prototype.slice.call(document.getElementsByClassName("apexcharts-canvas"), 0);

    if (canvas.length > 0) {
      for (let element of canvas) {
        element.remove();
      }
    }
    this.chartOptions.series.forEach(serie => {
      serie['dashArray'] = generarEstilo(); // Agregar un estilo aleatorio a cada serie
    });
    var chart = new ApexCharts(document.querySelector("#chart"), this.chartOptions);
    chart.render();
  }
  ngOnInit(): void {
    this.service.getBB(this.ot).subscribe(res => {
      if (res.status_code != "400") {
        this.datos = res;
        let totalBat = 0;
        this.datos.batteryDetails.forEach(d => {
          if (d.descripcion.toLowerCase().includes('bat')) {
            //  if (d.descripcion.includes('bat')) {
              this.sumaTabla1.med_on += d.med_on;
            this.sumaTabla1.med_0 += d.med_0;
            this.sumaTabla1.med_1 += d.med_1;
            this.sumaTabla1.med_2 += d.med_2;
            this.sumaTabla1.med_3 += d.med_3;
            this.sumaTabla1.med_4 += d.med_4;
            totalBat++;
            this.table2.push(d)
          } else {
            console.log(d)
            this.sumaTabla2.med_on += d.med_on;
            this.sumaTabla2.med_0 += d.med_0;
            this.sumaTabla2.med_1 += d.med_1;
            this.sumaTabla2.med_2 += d.med_2;
            this.sumaTabla2.med_3 += d.med_3;
            this.sumaTabla2.med_4 += d.med_4;

            let color = colorAleatorio(this.chartOptions.series.length);
            let data = [d.med_on, d.med_0, d.med_1, d.med_2, d.med_3, d.med_4]
            let obj: any = { name: d.descripcion, data: data.filter(d => d), color }
            this.chartOptions.yaxis.push({
              title: {
                //text: "Eje y para " + d.descripcion,
              },
              labels: {
                style: {
                  colors: color,
                },
              }
             
            })
            obj.yaxis = this.chartOptions.yaxis.length - 1;
            this.chartOptions.series.push(obj)
            this.table1.push(d)

          }


        })


        this.promedio.med_on = (this.sumaTabla1.med_on / totalBat).toFixed(2);
        this.promedio.med_0 = (this.sumaTabla1.med_0 / totalBat).toFixed(2);
        this.promedio.med_1 = (this.sumaTabla1.med_1 / totalBat).toFixed(2);
        this.promedio.med_2 = (this.sumaTabla1.med_2 / totalBat).toFixed(2);
        this.promedio.med_3 = (this.sumaTabla1.med_3 / totalBat).toFixed(2);
        this.promedio.med_4 = (this.sumaTabla1.med_4 / totalBat).toFixed(2);


      } else {
        this.mensaje404 = true;
      }

      this.graficar();


    })
    $('#spinner-load').css("display", "block");
    $('#spinner-load').css("display", "none");


  }

  fueraDeRango(valor, promedio) {
    let c1 = promedio + promedio * 0.05;
    let c2 = promedio - promedio * 0.05;

    if (valor > c1 || valor < c2) {
      return "fuera-de-rango"
    }
  }

  definirUnidad(descripcion) {
    if (descripcion.includes("Pres") && descripcion.includes("Aceite")) {
      return "Pres. Aceite (kPa)";
    }
    if (descripcion.includes("Temp") && descripcion.includes("Aceite")) {
      return "Temp. Aceite (°C)";
    }
    if (descripcion.includes("Tensión") && descripcion.includes("Total")) {
      return "Tensión Total(Vcc)";
    }
    if (descripcion.includes("Rama")) {
      return "I Rama 001(Acc)	";
    }
    if (descripcion.includes("Final") && descripcion.includes("BB")) {
      return "V.Final BB(Vcc)";
    }
    return descripcion;
  }
}

function generarEstilo() {
  const estilos = ['solid', 'dotted', 'dashed']; // Puedes agregar más estilos aquí si lo deseas
  const indiceAleatorio = Math.floor(Math.random() * estilos.length);
  return estilos[indiceAleatorio];
}

function colorAleatorio(index) {
  var coloresUsados = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0'];
  return coloresUsados[index];

}

