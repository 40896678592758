<div class="ui container" *ngIf="!mensaje404">
    <app-header-component title='BANCO DE BATERIAS' [botonBoroscopia]='false' [botones]="false">
    </app-header-component>
    <div id="">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="datos">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS DE LA CABECERA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label '> N° OT :<span class='negrita'>{{datos.nro_ot}} </span></td>
                        <td class='label'> Fecha Registro<span class='negrita'>{{datos.fecha_registro}}</span></td>
                    </tr>
                    <tr>
                        <td class='label '> V. Flote :<span class='negrita'>{{datos.v_flote}} Vcc</span></td>
                        <td class='label'>V. Fondo:<span class='negrita'>{{datos.v_fondo}} Vcc</span></td>
                    </tr>
                    <tr>
                        <td class='label '> V. Nominal :<span class='negrita'>{{datos.v_nominal}} Vcc</span></td>
                        <td class='label'>V .Final BB :<span class='negrita'>{{datos.v_fainl_bb}} Vcc</span></td>
                    </tr>
                    <tr>
                        <td class='label '> Autonomía :<span class='negrita'>{{datos.autonomia}} Hs</span></td>
                        <td class='label'>Intervalo :<span class='negrita'>{{datos.intervalo}} min</span></td>
                    </tr>
                    <tr>
                        <td class='label '> Cant, Bat. en Serie :<span class='negrita'>{{datos.capacidad_ah_x_bateria}}
                            </span></td>
                        <td class='label'>Ramas en Paralelo :<span class='negrita'>{{datos.ramas_en_paralelo}} </span>
                        </td>
                    </tr>
                    <tr>
                        <td class='label '>Capacidad AH x Bat :<span class='negrita'>{{datos.cantidad_en_serie}} </span>
                        </td>
                        <td class='label'>Fabricante :<span class='negrita'>{{datos.fabricante}} </span></td>
                    </tr>
                    <tr>
                        <td class='label '>Denomin.tipo :<span class='negrita'>{{datos.denominacion}} </span></td>
                        <td class='label'>Año const :<span class='negrita'>{{datos.anio}} </span></td>
                    </tr>
                    <tr>
                        <td class='label '>Fecha Inicio:<span class='negrita'>{{datos.fecha_inicio}} </span></td>
                        <td class='label'>Hora Inicio:<span class='negrita'>{{datos.hora_inicio}} </span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column">
            <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="datos">
                <table class="ui single line table">
                    <thead>
                        <tr>
                            <!--class="center aligned"-->
                            <th >Tension Total Inmediata OFF (VCC)</th>
                            <th >Corriente Total Inmediata OFF (A)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!--class="center aligned"-->
                            <td style="width: 100px !important;white-space: pre-wrap;" >
                                <p>
                                    {{datos.tension_total_inmediata_off}}
                                </p>

                            </td>
                            <td style="width: 100px !important;white-space: pre-wrap;" >
                                <p>
                                    {{datos.cte_total_inmediata_off}}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="table1">
            <table class="ui single line table">
                <thead>
                    <tr>
                        <th class="center aligned">Datos</th>

                        <th class="center aligned" *ngFor="let item of table1">
                            <span>{{definirUnidad(item.descripcion)}}</span>
                        </th>

                    </tr>
                </thead>
                <tbody>

                    <tr *ngIf="sumaTabla2.med_on > 0">
                        <td style="width: 100px !important; white-space: pre-wrap;" class="center aligned">
                            <p>
                                Suma de MED ON
                            </p>
                        </td>
                        <td *ngFor="let item of table1" style="width: 100px !important; white-space: pre-wrap;"
                            class="center aligned">
                            <p>
                                {{ item.med_on }}
                            </p>
                        </td>

                    </tr>

                    <tr *ngIf="sumaTabla2.med_0 > 0">
                        <td style="width: 100px !important; white-space: pre-wrap;" class="center aligned">
                            <p>
                                Suma de MED 0
                            </p>
                        </td>
                        <td *ngFor="let item of table1" style="width: 100px !important; white-space: pre-wrap;"
                            class="center aligned">
                            <p>
                                {{ item.med_0 }}
                            </p>
                        </td>

                    </tr>
                    <tr *ngIf="sumaTabla2.med_1 > 0">
                        <td style="width: 100px !important; white-space: pre-wrap;" class="center aligned">
                            <p>
                                Suma de MED 1
                            </p>
                        </td>
                        <td *ngFor="let item of table1" style="width: 100px !important; white-space: pre-wrap;"
                            class="center aligned">
                            <p>
                                {{ item.med_1 }}
                            </p>
                        </td>

                    </tr>
                    <tr *ngIf="sumaTabla2.med_2 > 0">
                        <td style="width: 100px !important; white-space: pre-wrap;" class="center aligned">
                            <p>
                                Suma de MED 2
                            </p>
                        </td>
                        <td *ngFor="let item of table1" style="width: 100px !important; white-space: pre-wrap;"
                            class="center aligned">
                            <p>
                                {{ item.med_2 }}
                            </p>
                        </td>

                    </tr>
                    <tr *ngIf="sumaTabla2.med_3 > 0">
                        <td style="width: 100px !important; white-space: pre-wrap;" class="center aligned">
                            <p>
                                Suma de MED 3
                            </p>
                        </td>
                        <td *ngFor="let item of table1" style="width: 100px !important; white-space: pre-wrap;"
                            class="center aligned">
                            <p>
                                {{ item.med_3 }}
                            </p>
                        </td>

                    </tr>
                    <tr *ngIf="sumaTabla2.med_4 > 0">
                        <td style="width: 100px !important; white-space: pre-wrap;" class="center aligned">
                            <p>
                                Suma de MED 4
                            </p>
                        </td>
                        <td *ngFor="let item of table1" style="width: 100px !important; white-space: pre-wrap;"
                            class="center aligned">
                            <p>
                                {{ item.med_4 }}
                            </p>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>
        <!--<div class="row">-->
        <!--<div class="column">-->
        <div id="chart">
        </div>
        <!-- </div>-->


        <div class="column">
            <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="table2">
                <table class="ui single line table">
                    <thead>
                        <tr>
                            <th class="th-item">Descripción</th>
                            <th class="center aligned">MED ON</th>
                            <th class="center aligned" *ngIf="sumaTabla1.med_0 >0 ">MED 0</th>
                            <th class="center aligned" *ngIf="sumaTabla1.med_1 >0 ">MED 1</th>
                            <th class="center aligned " *ngIf="sumaTabla1.med_2 >0 ">MED 2</th>
                            <th class="center aligned " *ngIf="sumaTabla1.med_3 >0 ">MED 3</th>
                            <th class="center aligned " *ngIf="sumaTabla1.med_4 >0 ">MED 4</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of table2">
                            <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned">
                                <p>
                                    {{item.descripcion}}
                                </p>

                            </td>
                            <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned"
                                [ngClass]="fueraDeRango(item.med_on,promedio.med_on)">
                                <p>
                                    {{item.med_on}}
                                </p>

                            </td>

                            <td *ngIf="sumaTabla1.med_0 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned" [ngClass]="fueraDeRango(item.med_0,promedio.med_0)">

                                <p>
                                    {{item.med_0}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_1 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned" [ngClass]="fueraDeRango(item.med_1,promedio.med_1)">

                                <p>
                                    {{item.med_1}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_2 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned" [ngClass]="fueraDeRango(item.med_2,promedio.med_2)">

                                <p>
                                    {{item.med_2}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_3 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned" [ngClass]="fueraDeRango(item.med_3,promedio.med_3)">

                                <p>
                                    {{item.med_3}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_4 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned" [ngClass]="fueraDeRango(item.med_4,promedio.med_4)">
                                <p>
                                    {{item.med_4}}
                                </p>

                            </td>
                        </tr>

                        <tr>
                            <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned">
                                <p>
                                    SUMA
                                </p>

                            </td>
                            <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned">
                                <p>
                                    {{ sumaTabla1.med_on | number:'1.2-2' }}
                                </p>

                            </td>

                            <td *ngIf="sumaTabla1.med_0 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{sumaTabla1.med_0 | number:'1.2-2' }}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_1 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{sumaTabla1.med_1 | number:'1.2-2' }}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_2 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{sumaTabla1.med_2 | number:'1.2-2' }}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_3 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{sumaTabla1.med_3 | number:'1.2-2' }}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_4 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{sumaTabla1.med_4 | number:'1.2-2' }}
                                </p>

                            </td>
                        </tr>
                        <tr>
                            <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned">
                                <p>
                                    PROMEDIO
                                </p>

                            </td>
                            <td style="width: 100px !important;white-space: pre-wrap;" class="center aligned">
                                <p>
                                    {{promedio.med_on}}
                                </p>

                            </td>

                            <td *ngIf="sumaTabla1.med_0 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{promedio.med_0}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_1 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{promedio.med_1}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_2 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{promedio.med_2}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_3 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{promedio.med_3}}
                                </p>

                            </td>
                            <td *ngIf="sumaTabla1.med_4 >0 " style="width: 100px !important;white-space: pre-wrap;"
                                class="center aligned">
                                <p>
                                    {{promedio.med_4}}
                                </p>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>



    <!--</div>-->
</div>

<div class="ui container" *ngIf="mensaje404">
    <app-header-component title='BANCO DE BATERIAS' [botonBoroscopia]='false' [botones]="false">
    </app-header-component>
    <div style='overflow-x: auto;' style="margin-bottom: 2em;">
        <div class="ui warning message">
            <i class="close icon"></i>
            <div class="header">
                Aún no se han cargado datos para la OT {{ot}}
            </div>
        </div>
    </div>
</div>