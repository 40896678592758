<div class="ui grid">
    <div class="row">
        <div  class=" sixteen wide  column container-position" *ngIf="info.length > 1 ">
            <label class='label light-blue'>Seleccione una observación para visualizar:</label>
        </div>
        <div [style.margin-bottom]="'1em'"  class=" sixteen wide  column container-position" *ngIf="info.length > 1 ">
            <div [ngClass]="(indexInfo == i) ? 'position-active' :'' " class='number-index' (click)="change(i)"
                *ngFor="let item of info;index as i ">
                N° {{i + 1}}
            </div>
        </div>
        <div *ngFor="let item of info;index as i " class=" sixteen wide  column ">
            <app-gallery-modal *ngIf="indexInfo == i" [position]="0"
                [gallery]="item.gallery"></app-gallery-modal>
                <span [style.margin]="'auto'" [style.display]="'flex'">{{item.descripcion}}</span>
        </div>
    </div>
</div>