<div class="ui container">
    <app-header-component [botonBB]="true" [protocol]="descripcion" [ot]="numero_ot" [botonBoroscopia]='false'
        [title]='descripcion' [estado]='state'>
    </app-header-component>
    <div id="">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS PRELIMINARES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label '> OT:<span class='negrita'>{{numero_ot}}</span></td>
                        <td class='label'>N° de Equipo:<span class='negrita'>{{equipo}}</span></td>

                    </tr>
                    <tr>
                        <td class='label'>UT:<span class='negrita'>{{ut}}</span></td>
                        <td class='label'> Fecha:<span class='negrita'>{{fecha|
                                date:'dd/MM/yyyy'}}</span></td>
                    </tr>
                    <tr>
                        <td class='label'>Planta:<span class='negrita'>{{planta }}</span></td>
                        <td class='label'>Autor:<span class='negrita'>{{audito}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ui divider no-pdf-flex"></div>

        <div *ngFor="let item of actividades;index as i" class="container-protocol no-pdf">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="actividades.length>0">
                <div class="sixteen wide column">
                    <h4 class="title-style">{{item.actividad}}</h4>
                    <div class="ui styled accordion " *ngFor="let instruccion of item.items;index as i2"
                        style="margin-top: 0.5em;">
                        <div [id]="'panel'+item.actividad+i+i2" class="title "
                            (click)="activePanel(item.actividad+i+i2)">
                            <i class="dropdown icon"></i>
                            {{instruccion.title}} 
                            <i *ngIf="instruccion.cantAvisos.includes(instruccion.Instruction_Id)" class=" exclamation triangle icon font-red pointer">
                            </i>
                        </div>
                        <div [id]="'content'+item.actividad+i+i2" class="content ">
                            <app-table-results [nro]="numero_ot" [data]="instruccion"></app-table-results>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-pdf " *ngIf="resumen.length>0">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="resumen.length>0">
                <div class="sixteen wide column">
                    <div class="ui styled accordion " style="margin-top: 0.5em;">
                        <div [id]="'panelResumenBoroscopia'" class="title " (click)="activePanel('ResumenBoroscopia')">
                            <i class="dropdown icon"></i>
                            Resumen Boroscopia
                        </div>
                        <div [id]="'contentResumenBoroscopia'" class="content ">
                            <div *ngFor="let punto of resumen">

                                <h1 class="title ">
                                    {{punto.titulo}} - {{punto.description}}
                                </h1>
                                <table class="ui single line table">
                                    <thead>
                                        <tr>
                                            <th class="">Inspección para</th>
                                            <th class="">Resultados</th>
                                            <th class=" ">Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of punto.preguntas">
                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                {{p.texto}}
                                            </td>

                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.respuesta">{{p.respuesta.description}}</span>
                                                <span *ngIf="!p.respuesta">-</span>
                                            </td>
                                            <td colspan="2" style="width: 30% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.comentarios">{{p.comentarios.description}}</span>
                                                <span *ngIf="!p.comentarios">-</span>

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="4" class="center aligned"
                                                style="width: 70% !important;white-space: pre-wrap;">
                                                <div [style.flex-wrap]="'wrap'" [style.display]="'flex'" *ngIf="url">
                                                    <div [style.width]="'150px'"
                                                        *ngFor="let imagen of  punto.imagenes; index as i">
                                                        <div [style.margin]="'5px'">
                                                            <img [style.width]="'100%'"
                                                                (click)="verImagen(punto.imagenes, i)"
                                                                [src]="url+imagen.path" alt="">
                                                            <br>

                                                            <span>{{imagen.comentario}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="container-protocol no-pdf">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="actividades.length>0">
                <div class="sixteen wide column" *ngIf="baterias.length>0">
                    <h4 class="title-style">Banco de baterias </h4>
                    <div class="no-pdf " *ngIf="baterias.length>0">
                        <div class="ui grid " style="margin-top: 0.5em;">
                            <div class="sixteen wide column">
                                <div class="ui styled accordion " style="margin-top: 0.5em;">
                                    <div [id]="'panelbaterias'" class="title " (click)="activePanel('baterias')">
                                        <i class="dropdown icon"></i>
                                        Prueba de Descarga
                                    </div>
                                    <div [id]="'contentbaterias'" class="content ">

                                        <table class="ui single line table">
                                            <thead>
                                                <tr>
                                                    <th class=""></th>
                                                    <th class="">Med.ON</th>
                                                    <th class="">Med 0</th>
                                                    <th class="">Med 1</th>
                                                    <th class="">Med 2</th>
                                                    <th class="">Observaciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of baterias">

                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.name}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.on}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.cero}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.uno}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.dos}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.observacones}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div id="DATA-PDF" class="si-pdf">
        <div style='overflow-x: auto;' style="margin-bottom: 2em;" *ngIf="numero_ot">
            <table class="ui celled structured table unstackable">
                <thead>
                    <tr>
                        <th colspan="2" class="center aligned">DATOS PRELIMINARES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class='label '> OT:<span class='negrita'>{{numero_ot}}</span></td>
                        <td class='label'>N° de Equipo:<span class='negrita'>{{equipo}}</span></td>

                    </tr>
                    <tr>
                        <td class='label'>UT:<span class='negrita'>{{ut}}</span></td>
                        <td class='label'> Fecha:<span class='negrita'>{{fecha|
                                date:'dd/MM/yyyy'}}</span></td>
                    </tr>
                    <tr>
                        <td class='label'>Planta:<span class='negrita'>{{planta }}</span></td>
                        <td class='label'>Autor:<span class='negrita'>{{audito}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ui divider "></div>

        <div *ngFor="let item of actividades;index as i" class="container-protocol">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="actividades.length>0">
                <div class="sixteen wide column">
                    <h4 class="title-style">{{item.actividad}}</h4>
                    <div class="ui  " *ngFor="let instruccion of item.items;index as i2" style="margin-top: 0.5em;">
                        <div class="title ">
                            {{instruccion.title}}
                            <span *ngIf="instruccion.tag" class="tag">
                                TAG {{instruccion.tag}}
                            </span>
                            <i *ngIf="instruccion.avisos.length" class=" exclamation triangle icon font-red pointer">
                            </i>
                        </div>
                        <div>
                            <app-table-results [nro]="numero_ot" [data]="instruccion"></app-table-results>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="resumen.length>0">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="resumen.length>0">
                <div class="sixteen wide column">
                    <div class="ui styled accordion " style="margin-top: 0.5em;">
                        <div class="title ">
                            Resumen Boroscopia
                        </div>
                        <div>
                            <div *ngFor="let punto of resumen">

                                <h1 class="title ">
                                    {{punto.titulo}} - {{punto.description}}
                                </h1>
                                <table class="ui single line table">
                                    <thead>
                                        <tr>
                                            <th class="">Inspección para</th>
                                            <th class="">Resultados</th>
                                            <th class=" ">Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let p of punto.preguntas">
                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                {{p.texto}}
                                            </td>

                                            <td colspan="1" style="width: 35% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.respuesta">{{p.respuesta.description}}</span>
                                                <span *ngIf="!p.respuesta">-</span>
                                            </td>
                                            <td colspan="2" style="width: 30% !important;white-space: pre-wrap;"
                                                class="th-item">
                                                <span *ngIf="p.comentarios">{{p.comentarios.description}}</span>
                                                <span *ngIf="!p.comentarios">-</span>

                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="4" class="center aligned"
                                                style="width: 70% !important;white-space: pre-wrap;">
                                                <div [style.flex-wrap]="'wrap'" [style.display]="'flex'" *ngIf="url">
                                                    <div [style.width]="'150px'"
                                                        *ngFor="let imagen of  punto.imagenes; index as i">
                                                        <div [style.margin]="'5px'">
                                                            <img [style.width]="'100%'"
                                                                (click)="verImagen(punto.imagenes, i)"
                                                                [src]="url+imagen.path" alt="">
                                                            <br>

                                                            <span>{{imagen.comentario}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="container-protocol">
            <div class="ui grid " style="margin-top: 0.5em;" *ngIf="actividades.length>0">
                <div class="sixteen wide column" *ngIf="baterias.length>0">
                    <h4 class="title-style">Banco de baterias</h4>
                    <div class="">
                        <div class="ui grid " style="margin-top: 0.5em;">
                            <div class="sixteen wide column">
                                <div class="ui  " style="margin-top: 0.5em;">
                                    <div class="title ">
                                        Prueba de Descarga
                                    </div>
                                    <div>

                                        <table class="ui single line table">
                                            <thead>
                                                <tr>
                                                    <th class=""></th>
                                                    <th class="">Med.ON</th>
                                                    <th class="">Med 0</th>
                                                    <th class="">Med 1</th>
                                                    <th class="">Med 2</th>
                                                    <th class="">Observaciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of baterias">

                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.name}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.on}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.cero}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.uno}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.dos}}
                                                    </td>
                                                    <td colspan="1" style="white-space: pre-wrap;" class="th-item">
                                                        {{item.observacones}}
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ui grid">
        <div class="extra content">
            <button style="margin-top: 3em;" class="ui right labeled icon button button-filter primary"
                (click)="goHome()">
                <i class="home
                          icon"></i>
                Volver
            </button>
        </div>
    </div>
</div>