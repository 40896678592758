import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { RedirectService } from '../../../../core/services/redirect/redirect.service';
import { Menu, MenuItem } from '../../../../shared/models/menu';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, AfterContentChecked {

  public menu: Menu = new Menu();
  public username: string;
  public loaded = false;

  constructor(private userSerivice: UsuarioService, private redirectService: RedirectService) {
    console.log("v1 03-04-2024")
    }

  ngOnInit() {
  }

  public isAuthenticated() {
    return this.userSerivice.isLoggedIn();
  }

   logout() {
    this.userSerivice.clear()
    this.redirectService.toLogin();
  }



  ngAfterContentChecked(): void {
  }

  ngOnDestroy() {
    this.loaded = false;
    this.menu.items = [];
  }
}
