import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginModule} from '../modules/login/login.module';
import {ShellModule} from '../modules/shell/shell.module';
import {ShellRoutingModule} from '../modules/shell/shell-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import { SharedModule } from '../shared/components/shared.module';
import { HomeRoutingModule } from '../modules/home/home-routing.module';
import { AdministracionModule } from '../modules/administracion/administracion.module';
import { AdministracionRoutingModule } from '../modules/administracion/administracion-routing.module';
import { BoroscopiaModule } from '../modules/boroscopia/boroscopia.module';
import { BoroscopiaRoutingModule } from '../modules/boroscopia/boroscopia-routing.module';

@NgModule({
  providers:[
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true
    }
  ],
  imports:[
    CommonModule, 
    HomeRoutingModule,
    BrowserAnimationsModule, 
    ShellRoutingModule,
    AdministracionRoutingModule,
    BoroscopiaRoutingModule,
    SharedModule],
   
  exports:[
    LoginModule, 
    ShellModule,
    AdministracionModule,
    BoroscopiaModule
]
})
export class CoreModule {
}
