<mat-dialog-content style=' padding:5px 0px!important;max-height: 70vh!important' (swipeleft)="changeImg(1)"
  (swiperight)="changeImg(-1)">
  <i [mat-dialog-close]="true" class="icon times"></i>

  <div class="ui grid">
    <div class="row">
      <div class="two wide column">
        <button fxHide fxShow.gt-sm *ngIf="gallery.length > 1 " mat-button (click)="changeImg(-1)">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>
      <div class="twelve wide column" *ngFor="let image of gallery" [ngClass]="(position == image.position) ? 'img-visible' : 'img-hidden'" >
        <p>{{titulo}}</p>
        <img class=" img-modal" [src]="image.src" [alt]="image.alt" />
        <p>{{image.descripcion}}</p>
        <p>{{descripcion}}</p>
        <!--<p>{{image.position+1}} de {{gallery.length}}</p>-->
      </div>
      <div class="two wide column">
        <button fxHide fxShow.gt-sm *ngIf="gallery.length > 1  " mat-button (click)="changeImg(1)" style='right: 10px;'>
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>