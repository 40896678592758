export const environment = {

/*
    production: true,    
 
   //apiUrl: 'https://test.smartworkar.com:8888',
   apiUrl: 'http://tgn.smartworkar.com:8888',
    frontUrl: '',
    urlAssets: 'http://localhost:4200',
    urlBack: 'https://tgn-turbocompresores-back.candoit.com.ar',
    user: {
        username: "tgntesting@vreadynow.com",
        password: "larealidadesvirtual"
    },
    userApi: {
        username: "user_api",
        password: "a"
    },
    mecanicos: ['Anual', 'Anual 8000', '8000']
    
    */
    
//QA    
    /*production: true,
    apiUrl: 'http://tgnrapoc.tgn.com.ar:8888',
    frontUrl: '',
    urlAssets: 'http://tgnrapoc.tgn.com.ar:8088/turbocompresores',
    urlBack: 'http://tgnrapoc.tgn.com.ar:8088/tgn-turbocompresores-back',
    user: {
        username: "api_client@vreadynow.com",
        password: "cli3ntPass3470"
    },
    mecanicos: ['Anual', 'Anual 8000', '8000'],
    userApi:{"username": "user_api", "password": "tgn_qa"}*/
    
      
 
    production: true,
    apiUrl: 'http://tgnrap.tgn.com.ar:8888',
    frontUrl: '',
    urlAssets: 'http://tgnrap.tgn.com.ar:8088/turbocompresores',
    urlBack: 'http://tgnrap.tgn.com.ar:8088/tgn-turbocompresores-back',
    user: {
        username: "vrnteam@vreadynow.com",
        password: "larealidadesvirtual"
    },
    userApi: {
        username: "user_api",
        password: "tgn_prod"
    },
    mecanicos: ['Anual', 'Anual 8000', '8000']
     
 
 
 };
