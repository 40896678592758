import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServerService } from 'src/app/core/services/api/api-server.service';
import { Service } from 'src/app/core/services/misiones/misiones.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/core/services/usuario/usuario.service';
import { GalleryModalComponent } from 'src/app/shared/components/gallery-modal/gallery-modal.component';
import { GalleryImage } from 'src/app/shared/models/galeryImage';
import { environment } from 'src/environments/environment';
import { ModelComentarioComponent } from 'src/app/shared/components/model-comentario/model-comentario.component';
import { ModalObservacionesComponent } from '../../components/modal-observaciones/modal-observaciones.component';
declare var $: any;

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {
  numero_ot: any;
  fecha: any;
  planta: any;
  token: any;
  audito: any;
  equipo: any;
  descripcion: string;
  id: string;
  state: string;
  url = environment.urlBack;
  missionsIgnored = []
  actividades: any[] = []
  mensajes: any[] = []
  punajes: any[] = [];
  resumen: any[] = [];
  repuestosFueraDelPlan: any[] = [];
  repuestosPlanificados: any[] = [];
  definiciones: any[] = [];
  idsSubtabla = 0
  cargaInicialStatus: any;
  instrucionesItems = []
  ejecutante: any;
  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private rutaActiva: ActivatedRoute,
    private service: Service,
    private serviceApi: ApiServerService,
    public dialog: MatDialog,

  ) {
    this.initializeData();
  }

  initializeData(): void {

    $("#spinner-load").css("display", "block");

    this.descripcion = this.rutaActiva.snapshot.params.descripcion;
    this.id = this.rutaActiva.snapshot.params.id;
    this.state = this.rutaActiva.snapshot.params.state;
    //obtenemos las definiciones
    this.service.getDefinitions(this.descripcion).subscribe(res => {
      this.definiciones = (res.data.Input_Ids)
    })

    this.checkEnabledBoroscopial()
    //obtenemos datos destacados de la boroscopia
    this.serviceApi.getBoroscopiaDestacadas('?ot=' + this.id).subscribe(res => {
      if (res.status_code !== '404') {
        this.resumen = res.secciones.filter(d => d.preguntas.length > 0);
      }
    })
    //obtenemos datos del detalle
    this.service.getByProtocolAndOt(this.descripcion, this.id).subscribe(results => {

      if (results) {
        this.cargaInicialStatus = results.Status;
        this.fecha = results.When;
        this.numero_ot = results.Status.OT.Observed;
        this.planta = results.Status.UT.Observed;
        this.equipo = new Number(results.Status.SNEquipo.Observed);
        this.service.getDatosResponsable(results.Mission_Id).subscribe(res => {
          if (res) {
            this.ejecutante = res.Status.Nombre.Observed + '  ' + res.Status.Apellido.Observed;
          }
        })
      }

      this.token = this.usuarioService.getToken();


      this.service.getMissionId(this.descripcion, this.id).subscribe(missionId => {

        this.service.getActivities(missionId).subscribe(actividades => {
          this.mensajes = (actividades.data)


          this.serviceApi.getComentarios(this.id).subscribe(comentarios => {
            let actividades: any = this.groupByActividad(this.mensajes);
            actividades.sort((a, b) => b.sec - a.sec);
            actividades = actividades.filter(d => !d.key.includes('Mtto') && !d.key.includes('mtto'))
            let nuevasActividades: any = {};
            for (let key2 in actividades) {

              let obj: any = actividades[key2];
              if (obj.key.includes('Balance')) {
                let indexPlanificados = 1;
                let indexFueraDePlan = 1;
                obj.results.forEach(b => {
                  if (b.Instruction_Id == 'RepuestosFueraDelPlan') {
                    for (let key in b.Status) {
                      if (b.Status['CodigoSapItem_' + indexFueraDePlan]) {
                        let nuevo = {
                          codigo: (b.Status['CodigoSapItem_' + indexFueraDePlan]) ? b.Status['CodigoSapItem_' + indexFueraDePlan].Observed : '',
                          descripcion: (b.Status['ObservacionItem_' + indexFueraDePlan]) ? b.Status['ObservacionItem_' + indexFueraDePlan].Observed : '',
                          cant: (b.Status['CantidadItem_' + indexFueraDePlan]) ? b.Status['CantidadItem_' + indexFueraDePlan].Observed : '',
                        }
                        if (nuevo.codigo != '' || nuevo.descripcion != '' || nuevo.cant != '') {
                          indexFueraDePlan++;
                          this.repuestosFueraDelPlan.push(nuevo)
                        }
                      }

                    }
                  }

                  if (b.Instruction_Id == 'RepuestosPlanificados') {
                    for (let key in b.Status) {
                      let nuevo = {
                        codigo: (this.cargaInicialStatus['Item' + indexPlanificados]) ? this.cargaInicialStatus['Item' + indexPlanificados].Observed : '',
                        cantReal: b.Status[key].Observed,
                        descripcion: (this.cargaInicialStatus['DscItem' + indexPlanificados]) ? this.cargaInicialStatus['DscItem' + indexPlanificados].Observed : '',
                        cantPlanificada: (this.cargaInicialStatus['CantItem' + indexPlanificados]) ? this.cargaInicialStatus['CantItem' + indexPlanificados].Observed : '',
                      }
                      indexPlanificados++;
                      this.repuestosPlanificados.push(nuevo)

                    }

                  }
                })
                nuevasActividades[obj.key] = actividades[key2].results;

              } else {
                nuevasActividades[obj.key] = actividades[key2].results;
              }
            }
            for (let keyActividad in nuevasActividades) {
              if (!keyActividad.includes("Balance de repuestos planificados y fuera del plan")) {
                let items: any[] = []
                let fotos: any[] = []
                let observaciones: any[] = []
                let avisos: any[] = []
                let jsonFiltrados = this.filtrarUltimas(nuevasActividades[keyActividad]);
                jsonFiltrados.forEach(d => {

                  if (d.Instruction_Dsc != 'Carga Inicial') {


                    if (d.Instruction_Id.startsWith('Foto') && d.Status[d.Instruction_Id]) {
                      fotos.unshift(d)
                    } else
                      if (d.Evidence_Type == "AvisoDeMantenimiento") {

                        observaciones.push(d)
                      } else if (d.Instruction_Id.startsWith("TCAvisoMtto")) {
                        let respuesta = d.Status[d.Instruction_Id];
                        d.respuesta = respuesta.Observed;
                        if (respuesta.Observed !== 'N/A') avisos.unshift(d);
                      } else {
                        this.instrucionesItems.push('Foto' + d.Instruction_Id)
                        let respuesta = d.Status[d.Instruction_Id];
                        if (respuesta) {
                          if (respuesta.Evidence) {
                            //items con imagenes
                            d.respuesta = '-';
                            respuesta.id = d.Mission_Id;
                            d.fotos = respuesta;
                            items.unshift(d)

                          } else {
                            //items cuestionario y formulario
                            for (let keyValue in d.Status) {
                              if (!keyValue.includes('Tolerancia') && !keyValue.includes('Unidad') && !keyValue.includes('valor')) {
                                if (this.definiciones[keyValue] != null && this.definiciones[keyValue].Label.trim() !== '') {
                                  d.label = this.definiciones[keyValue].Label;
                                } else {
                                  d.label = d.Instruction_Dsc;
                                }
                                d.label += this.textoNominal(keyValue, d);
                                d.red = this.definirTolerancia(keyValue, d)
                                d.respuesta = respuesta.Observed;
                              }
                            }

                            items.unshift(d)

                          }
                        } else {

                          const copiaObj = Object.assign({}, d);

                          d.respuesta = ''
                          copiaObj.subtabla = [];


                          let aux: any[] = [];

                          for (let keyValue in d.Status) {
                            if (!keyValue.includes('Tolerancia') && !keyValue.includes('Unidad') && !keyValue.includes('valor')) {
                              let respuesta = d.Status[keyValue].Observed;

                              if (respuesta !== '') {
                                let nuevo: any = {}

                                if (this.definiciones[keyValue] != null && this.definiciones[keyValue].Label.trim() !== '') {

                                  nuevo.label = this.definiciones[keyValue].Label;
                                } else {
                                  nuevo.label = this.formatearLabel(keyValue, respuesta);
                                }
                                nuevo.valor = this.getUnidad(keyValue, respuesta);
                                nuevo.label = nuevo.label + this.textoNominal(keyValue, d);
                                nuevo.red = this.definirTolerancia(keyValue, d)
                                console.log(nuevo)
                                aux.push(nuevo)
                              }

                            }

                          }

                          aux = this.ordenarLabels(aux);
                          d.subtabla = this.eliminarDuplicadosPorLabel(aux.reverse());
                          d.subtabla.reverse();
                          d.mostrarImagen = this.imagenExtra(d);
                          if (d.Instruction_Id == "DatosResponsable") {
                            console.log(d)
                          }
                          items.unshift(d)
                        }
                      }
                  }
                })



                let itemsFotos = fotos.filter(d => !this.instrucionesItems.includes(d.Instruction_Id))

                itemsFotos.forEach(f => {
                  if (f.Instruction_Id == 'FotoSistemaDeEntradaAire') {
                    console.log(f)
                  }
                  f.fotos = f.Status[f.Instruction_Id]
                  f.fotos.id = f.Mission_Id;
                  items.push(f)
                })

                let item = {
                  items: items,
                  avisos: avisos,
                  observaciones: observaciones,
                  fotos: fotos,
                  actividad: keyActividad,
                  comentarios: comentarios.commentsDTOs,
                  columnaFotos: !(keyActividad == 'Balance de repuestos fuera del plan')
                };
                if (keyActividad && keyActividad !== 'Cierre y finalización del mtto') this.actividades.unshift(item)
              } else {
                this.actividades.unshift("repuestos")
              }

            }
            $('#spinner-load').css("display", "none");
          });
        })

      });

    });
  }


  eliminarDuplicadosPorLabel(arr) {
    const uniqueLabels = new Set();
    const result = [];

    for (const item of arr) {
      if (!uniqueLabels.has(item.label)) {
        uniqueLabels.add(item.label);
        result.push(item);
      }
    }

    return result;
  }

  transformString(cadena: string): string {
    const coincidencias = cadena.match(/[a-z]+|[A-Z][a-z]*|\d+/g) || [];

    let nuevaCadena = coincidencias.join(' ');
    if (/([A-Z])\s(?=[A-Z])/g.test(nuevaCadena)) { nuevaCadena = nuevaCadena.replace(/([A-Z])\s(?=[A-Z])/g, '$1'); }
    if (/T\s\d+$/.test(nuevaCadena)) { nuevaCadena = nuevaCadena.replace(/T\s/g, 'T'); }
    if (/Y(?!\s)/.test(nuevaCadena)) { nuevaCadena = nuevaCadena.replace(/Y/g, 'Y '); }
    nuevaCadena = nuevaCadena.toLowerCase();
    nuevaCadena = nuevaCadena.charAt(0).toUpperCase() + nuevaCadena.slice(1);
    return nuevaCadena;
  }



  imagenExtra(d) {
    const opcionesArray = ['VibracionesCojinetes'];
    for (const opcion of opcionesArray) {
      if (d.Instruction_Id == (opcion)) {
        return true;
      }
    }
    return false;
  }
  eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
  }
  reordenarActividades(array: any) {
    const orden = [];

    // Crear un mapa que asocie cada actividad con su posición en el nuevo orden
    const ordenMap = new Map();
    orden.forEach((elem, index) => {
      ordenMap.set(this.eliminarDiacriticos(elem), index);
    });

    // Ordenar el array original en función del mapa
    array.sort((a, b) => {
      const actividadA = this.eliminarDiacriticos(a.actividad);
      const actividadB = this.eliminarDiacriticos(b.actividad);

      const ordenA = ordenMap.get(actividadA);
      const ordenB = ordenMap.get(actividadB);

      return ordenA - ordenB;
    });

    return array;
  }

  filtrarUltimas(preguntas: any) {
    const map = new Map<string, any>();
    preguntas.forEach(obj => {
      const compositeKey = `${obj.Instruction_Id}_${obj.Evidence_Type}`;
      const existingObj = map.get(compositeKey);
      if (!existingObj || obj.When > existingObj.When) {
        map.set(compositeKey, obj);
      }
    });
    const result = Array.from(map.values());
    return result;
  }

  ordenarPreguntas(arr: any) {
    return arr.sort((x, y) => x.Instruction_Seq - y.Instruction_Seq);
  }

  preguntasAdicionales(pregunta: any, respuesta: any) {
    if (pregunta && respuesta) {
      const preguntaAdicional = pregunta.Status.PreguntaAdicional.Observed;

      if (preguntaAdicional !== '') {
        const data = {
          Activity_Dsc: pregunta.Activity_Dsc,
          Mission_Id: pregunta.Mission_Id,
          Protocol_Id: pregunta.Protocol_Id,
          Status: {
            PreguntaAdicional: {
              Text: preguntaAdicional,
              Observed: respuesta.Status,
            },
          },
          Instruction_Id: "PreguntaRespuestaAdicional",
          _id: pregunta._id,
        };

        return data;
      }
    }

    return null; // No se cumple la condición, no hay datos para devolver.
  }


  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    $('.ui.accordion').accordion();
  }
  goHome() {
    this.router.navigate(['/']);
  }
  getIdComentario(item) {

    let key = '';
    for (let key in item.Status) {
      key = key;
    }
    let instruccion = item['Instruction_Dsc']
    return item['Mission_Id'] + "-" + item['Activity_Dsc'] + "-" + key + '-' + instruccion.replace(/[^\w\s]/g, '');
    ;
  }

  verComentario(item, comentarios) {
    this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { nro: this.numero_ot, _id: this.getIdComentario(item), comentarios: comentarios, type: "ver" } });
  }


  showObservaciones(data, titulo) {
    let info = []
    data.lista.forEach(item => {
      let observacion: any = {}
      observacion.gallery = [];
      let fotos = item.Evidence;
      observacion.descripcion = item.Observed;
      var i = 0;
      fotos.forEach(f => {
        const image = new GalleryImage();
        image.alt = f;
        image.src = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
        image.position = i;
        observacion.gallery.push(image);
        i = i + 1;
      })
      info.push(observacion)

    })

    this.dialog.open(ModalObservacionesComponent, { panelClass: 'custom-dialog-container', data: { titulo: titulo, info: info } });
  }


  newComentario(item, comentarios) {
    let id = this.getIdComentario(item);
    const dialogRef = this.dialog.open(ModelComentarioComponent, { panelClass: 'custom-dialog-container', data: { _id: id, nro: this.numero_ot, comentarios: comentarios, type: "crear" } });
    dialogRef.afterClosed().subscribe(() => {
      this.serviceApi.getComentario(id).subscribe(res => {
        let comentarios = res.commentsDTOs;
        item.comentarios = (comentarios) ? comentarios : [];
      })
    });
  }



  groupByActividad(data) {
    const key = "Activity_Dsc";
    return Object.entries(data.reduce((acc, cur) => {
      const actividad = cur[key];
      const sec = cur["Activity_Seq"];

      if (!acc[actividad]) {
        acc[actividad] = {
          key: actividad,
          sec: sec,
          results: [],
        };
      }

      const curCopy = { ...cur };
      acc[actividad].results.push(curCopy);

      return acc;
    }, {})).map(([key, value]) => value);
  }


  verImagen(array: any, position: any) {
    let gallery = [];
    var i = 0;
    array.forEach(f => {
      const image = new GalleryImage();
      image.alt = f;
      //image.imagen = `${environment.apiUrl}/api/instance/instancia-de-prueba/mission/Mission_${data.id}/file/${f}?token=${this.token}`;
      image.src = this.url + f.path;
      image.descripcion = f.comentario;
      image.position = i;
      gallery.push(image);
      i = i + 1;
    })
    this.dialog.open(GalleryModalComponent, { panelClass: 'custom-dialog-container', data: { titulo: '', gallery: gallery, position: position } });

  }
  activePanel(i) {

    if (document.getElementById("panel" + i).classList.contains("active")) {
      document.getElementById("panel" + i).classList.remove("active");
      document.getElementById("content" + i).classList.remove("active");
    } else {
      document.getElementById("panel" + i).classList.add("active");
      document.getElementById("content" + i).classList.add("active");
    }

  }

  formatearNumero(respuesta) {
    if (!isNaN(respuesta)) {
      if (respuesta % 1 !== 0) {
        respuesta = Number(respuesta).toFixed(2);
      }
    }
    return respuesta;
  }
  enabledBoroscopia = false;
  checkEnabledBoroscopial() {
    this.enabledBoroscopia = this.state == "Finalizado" && (this.usuarioService.getRoles().includes('Jefe de Análisis de Condición') || this.usuarioService.getRoles().includes('Evaluador Tec.Planta'));
  }

  formatearLabel(keyValue, respuesta,) {
    const split = keyValue.split('_');
    if (!isNaN(respuesta)) respuesta = this.formatearNumero(respuesta);
    return this.transformString(split[0])
  }
  getUnidad(keyValue, respuesta) {
    const split = keyValue.split('_');

    let unidadParcial = (split[1] && !(/^\d+$/.test(split[1]))) ? " " + split[1] : '';

    let unidad = (unidadParcial.trim() == 'porcentaje') ? '%' :
      (unidadParcial.trim() == 'grados') ? "°" : unidadParcial;

    if (!isNaN(respuesta) && respuesta.includes('.')) {
      respuesta = parseFloat(respuesta).toFixed(2);
    }

    respuesta = respuesta + unidad;

    return respuesta;
  }
  definirTolerancia(keyValue, d) {
    let keyTolerancia = "Tolerancia_" + keyValue.replace(/_.*/, '');
    let tolerancia = (d.Status[keyTolerancia]) ? d.Status[keyTolerancia].Observed : "";
    return (tolerancia == 'Fuera de rango esperado')
  }
  textoNominal(keyValue, d) {
    let keyValorNominal = keyValue.replace(/_.*/, '') + "_valor";
    let nominal = d.Status[keyValorNominal];
    return (nominal) ? '. Valor esperado : ' + nominal.Observed : '';
  }
  ordenarLabels(aux) {
    const fraseComun = aux.length > 0 ? aux[0].label.match(/^.*?\D+/) : null;
    const todosTienenFraseComun = fraseComun && aux.every(item => item.label.startsWith(fraseComun[0]));

    if (todosTienenFraseComun) {
      aux.sort((a, b) => {
        let indexa = a.label;
        let indexb = b.label;
        let numerosa = indexa.replace(/[^0-9]/g, '');
        let numerosb = indexb.replace(/[^0-9]/g, '');

        if (numerosa && numerosb) {
          indexa = parseFloat(numerosa);
          indexb = parseFloat(numerosb);
        }
        if (indexa < indexb) {
          return -1;
        }
        if (indexa > indexb) {
          return 1;
        }
        return 0;
      });
    }
    return aux;
  }
}


