import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { DetalleComponent } from './pages/detalle/detalle.component';
import { DetalleInstrumentalesComponent } from './pages/detalle-instrumentales/detalle-instrumentales.component';
import { BancoDeBateriasComponent } from './pages/banco-de-baterias/banco-de-baterias.component';


const routes: Routes = [
  { path: 'detalle-mecanicos/:id/:descripcion/:state', component: DetalleComponent, canActivate: [AuthGuard,AuthGuard] },
  { path: 'detalle-instrumentales/:id/:descripcion/:state', component: DetalleInstrumentalesComponent, canActivate: [AuthGuard,AuthGuard] },
  { path: 'banco-baterias/:ot', component: BancoDeBateriasComponent, canActivate: [AuthGuard,AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministracionRoutingModule { }
