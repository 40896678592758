import { NgModule } from '@angular/core';
import { BoroscopiaComponent } from './pages/boroscopia/boroscopia.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  { path: 'boroscopia/:descripcion/:ot', component: BoroscopiaComponent, canActivate: [AuthGuard] },
  { path: 'boroscopia', component: BoroscopiaComponent, canActivate: [AuthGuard] },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BoroscopiaRoutingModule { }
